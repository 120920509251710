/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class AccessLevel {
        static Public: PartnershipsModule.IAccessLevel = 'public'
        static Anonymous: PartnershipsModule.IAccessLevel = 'anon'
        static LoggedInUser: PartnershipsModule.IAccessLevel = 'loggedinuser'
        static AdminLoggedIn: PartnershipsModule.IAccessLevel = 'admin-loggedin'
        static AdminAnonymous: PartnershipsModule.IAccessLevel = 'admin-anon'
    }

    function accessLevelConfig(AccessLevelProvider: PartnershipsModule.AccessLevelProvider) {

        AccessLevelProvider.accesslevel(AccessLevel.Public, {
        })

        AccessLevelProvider.accesslevel(AccessLevel.Anonymous, {
            roles: ['PUB']
        })

        AccessLevelProvider.accesslevel(AccessLevel.LoggedInUser, {
            roles: ['USR', 'ADM']
        })

        AccessLevelProvider.accesslevel(AccessLevel.AdminLoggedIn, {
            condition: ['$rootScope', function ($rootScope: PartnershipsModule.IRootScopeService) {
                return $rootScope.userInfo != null && $rootScope.userInfo.isAuthenticated
            }]
        })

        AccessLevelProvider.accesslevel(AccessLevel.AdminAnonymous, {
            condition: ['$rootScope', function ($rootScope: PartnershipsModule.IRootScopeService) {
                return $rootScope.userInfo == null || !$rootScope.userInfo.isAuthenticated
            }]
        })
    }

    angular
        .module('app')
        .config(accessLevelConfig)

}