/// <reference path="../../typings/references.d.ts" />

// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************

module PartnershipsModule {

    function MenuConfig(MenuProvider) {

        MenuProvider.AddMenu('anon', 'contact', {
            items: [
                {
                    name: 'Get in Touch',
                    state: 'app.contact.getInTouch'
                },
                {
                    name: 'London Office',
                    state: 'app.contact.london'
                }
            ]
        });

        MenuProvider.AddMenu('applicant', 'contact', {
            items: [
                {
                    name: 'Get in Touch',
                    state: 'app.contact.getInTouch'
                },
                {
                    name: 'London Office',
                    state: 'app.contact.london'
                },
                {
                    name: 'Your Agency',
                    state: 'app.contact.agency'
                }
            ]
        });
    }

    angular
        .module('app')
        .config(MenuConfig);

}