/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class AdminLocationsController {

        static $inject = ['locations']

        constructor(public locations) {
        }
    }

    angular
        .module('app')
        .controller('AdminLocationsController', AdminLocationsController)

}