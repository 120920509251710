/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class MyTicketsController {

        static $inject = ['$scope', 'bookings'];

        constructor($scope, bookings) {

            $scope.bookings = bookings;

        }
    }

    angular
        .module("app")
        .controller("MyTicketsController", MyTicketsController);

}