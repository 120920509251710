/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    function link(scope, elem, attrs, ctrls) {

        var formCtrl = ctrls[0];

        formCtrl.submit = function () {
            elem.submit();
        }
    }

    angular
        .module("app")
        .directive('aifsSubmit', function () {
            return {
                restrict: 'A',
                require: ['^form'],
                link: link
            };
        });

}