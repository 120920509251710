/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminActivityDetailsController {

        response

        private _activity
        private defaultZoom: number = 19
        private _location
        private file

        public uploading : boolean

        map = {
            center: {
                latitude: 54.6,
                longitude: -4.6
            },
            zoom: 5
        }

        static $inject = ['$scope', '$q','activity', 'ActivityDetails', 'locations', 'Upload']

        constructor(private $scope, private $q, public activity, private ActivityDetails, public locations, private Upload) {
            this._activity = angular.copy(activity)
            this._location = angular.copy(activity.location)
            this.zoomToLocation()
        }

        resetResponse() {
            this.response = null
        }

        zoomToLocation(location = this._location, zoom: number = this.defaultZoom): void {
            this.map.center.latitude = location.latitude
            this.map.center.longitude = location.longitude
            this.map.zoom = zoom
        }

        fileChanged() {
            this.activity.path = URL.createObjectURL(this.file);
            //this.activity.mainImage = this.file.name;
        }


        uploadDocument() {
            if(this.file) {
                this.uploading = true;
                this.ActivityDetails
                    .uploadDocument({ activityId: this.activity.id }, {fileName: this.file.name})
                    .$promise
                    .then((response) => {
                        var image = this.file.name;
                        var key = response.filePattern + image.slice(image.lastIndexOf('.'));

                        return this.Upload.upload({
                            url: 'https://' + response.bucket + '.s3.amazonaws.com/',
                            method: 'POST',
                            fields: {
                                key: key,
                                AWSAccessKeyId: response.awsAccessKeyId,
                                acl: response.acl,
                                policy: response.policy,
                                signature: response.signature,
                                'Content-Type': this.file.type
                            },
                            file: this.file
                        }).then(() => {
                            this.file = undefined;
                            this.uploading = false;
                            this.refreshImage()
                        });
                    });
            }
        }

        deleteDocument() {
            this.ActivityDetails
                .deleteDocument({ activityId: this.activity.id })
                .$promise
                .then(() => this.refreshImage());

        }

        refreshImage() {
            this.ActivityDetails.query({ activityId: this.activity.id})
                .$promise
                .then((a) => {
                    this.activity.path = a.path;
                    this.activity.mainImage = a.mainImage;
                });
        }

        save() {
            return this.activity.$update()
                .then(() => this.$scope.form.$setPristine());
        }
    }

    angular
        .module('app')
        .controller('AdminActivityDetailsController', AdminActivityDetailsController)

}