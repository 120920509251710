/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    function remoteDirective($http, $q, dataAccessUrl) {

        function checkResponse(response) {
            if (response.status == 409 || response.data && response.data.isValid == false)
                return $q.reject();
        }

        function link(scope, elem, attrs, ctrls) {

            var url = attrs.aifsRemote;
            if (!/^(https?:)?[/][/]/.test(url))
                url = dataAccessUrl + url;

            ctrls[0].$asyncValidators.remote = function (modelValue, viewValue) {
                return $http({
                    url: url,
                    method: 'POST',
                    data: { value: viewValue }
                }).then(checkResponse, checkResponse);
            };
        }

        return {
            require: ['ngModel'],
            restrict: 'A',
            link: link
        };
    }

    angular
        .module('app')
        .directive('aifsRemote', remoteDirective);
}