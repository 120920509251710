/// <reference path="../typings/references.d.ts" />

module PartnershipsModule {

    angular
        .module('app', [
            'ngRoute',
            'ngResource',
            'ngMessages',
            'ui.router',
            'ct.ui.router.extras',
            'ui.router.title',
            'ui.validate',
            'ui.select', 'ngSanitize',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.bootstrap.showErrors',
            'frapontillo.bootstrap-switch',
            'uiGmapgoogle-maps',
            'angularLocalStorage',
            'dialogs',
            'AdalAngular',
            'ngFileUpload'
            //'ngMockE2E'
        ])
        .config(function (uiGmapGoogleMapApiProvider) {
            uiGmapGoogleMapApiProvider.configure({
                key: 'AIzaSyBC5Bu_B0l5eJsN7qpWTAJZuDMlm1-VDw0',
                libraries: 'places' // needed for search box
            });
        })
        .run(function ($rootScope, $state, $stateParams, /*$httpBackend,*/ storage, Menu, Auth, AccessLevel /*, PushHub */) {
            //console.log('In app.run state is ' + $state);

            //$rootScope.$state = $state;
            //console.log('In app.run #2');
            //$rootScope.$stateParams = $stateParams;
            //console.log('In app.run #3');
            //$rootScope.Menu = Menu;
            //console.log('In app.run #4');

            $rootScope.$on('auth:user-change', function (event, user) {
                AccessLevel.apply(user.role).then(function () {
                    $state.transitionTo($state.current, $stateParams, { reload: true, inherit: false, notify: true });
                });
            });
            //console.log('In app.run #5');
            $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
                if (toState.name !== 'app.home.events')
                    $state.transitionTo('app.home.events', { programmeCode: storage.get('programmeCode') }, { reload: true, inherit: false, notify: true });
                else
                    $state.transitionTo('app.home.events', { programmeCode: '' }, { reload: true, inherit: false, notify: true });

            });



            $rootScope.$on('$stateChangeStart', function () {
                $rootScope.$broadcast('loader:start');
            });

            $rootScope.$on('$stateChangeSuccess', function () {
                $rootScope.$broadcast('loader:stop');
            });

            //console.log('In app.run #6');

            //$rootScope.$on('$stateChangeSuccess', function () {
            //    $("html, body").animate({ scrollTop: 0 }, 200);
            //}); 
            //console.log('In app.run #7');
            AccessLevel.apply(Auth.user.role);

            //console.log('exiting app.run');

        });

}