/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class ContactController {

        static $inject = ['$scope'];

        constructor($scope) {
            $scope.send = function () {

            };
        }
    }

    angular
        .module("app")
        .controller("ContactController", ContactController);

}