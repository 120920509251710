/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    angular
        .module("app")
        .directive('file', function () {

            return {
                require:"ngModel",
                restrict: 'A',
                link: function($scope, el: any, attrs, ngModel:any){
                    el.bind('change', function(event){
                        var files = event.target.files;
                        var file = files[0];
         
                        ngModel.$setViewValue(file);
                        $scope.$apply();
                    });
                }
            };
        });

}