/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class Part {

        name: string;
        isActive: boolean = true;
        prommise: ng.IPromise<any>;
    }

    class ResourcesLoader {
        private parts: any = {};

        constructor(private $q, private $http) {
        }

        load() {
            var promisses = _.chain(this.parts)
                .filter((part: Part) => part.isActive)
                .map((part: Part) => part.prommise)
                .value();


            return this.$q
                .all(promisses)
                .then((parts) => _.reduce(parts, (result, part) => _.merge(result, part)));
        }

        addPart(name) {

            if (_.has(this.parts, name)) {
                this.parts[name].isActive = true;
            } else {
                var part = this.parts[name] = new Part();

                part.name = name;
                part.prommise = this.$http({ method: 'GET', url: `/templates/_resourses/${name}.json` })
                    .then((result) => result.data);
            }
            return this.parts[name].prommise;
        }

        isPartAvailable(name) {
            if (!angular.isString(name) || name === '') {
                throw new TypeError('Invalid type of a first argument, a non-empty string expected.');
            }

            return (_.has(this.parts, name) && this.parts[name].isActive);
        }

    }

    class ResourcesLoaderProvider implements ng.IServiceProvider {

        private resourcesLoader: ResourcesLoader;

        $get = ['$q', '$http', function ($q, $http) {

            if (!this.resourcesLoader)
                this.resourcesLoader = new ResourcesLoader($q, $http);

            return this.resourcesLoader;
        }];
    }

    angular
        .module('app')
        .provider('ResourcesLoader', ResourcesLoaderProvider);

}