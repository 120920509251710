/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class ChangePasswordController {

        static $inject = ['$scope', 'Auth', 'storage'];
        constructor($scope, Auth, storage) {


            $scope.user = {};
            $scope.forms = {};

            $scope.save = function () {
                if ($scope.forms.changePassword.$valid) {

                }
            }

        }
    }


    angular
        .module('app')
        .controller('ChangePasswordController', ChangePasswordController);

}