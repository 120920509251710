/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminCreateProgrammeController {

        response

        programme

        static $inject = ['$scope', '$state', 'ProgrammeDetails', 'semesterCode', 'currencies', 'accountCodes']

        constructor(private $scope, private $state, private ProgrammeDetails, private semesterCode, public currencies, public accountCodes) {
            this.programme = {}
            this.programme.semesterCode = semesterCode
        }

        create() {
            this.ProgrammeDetails.create(this.programme).$promise.then(
                (response) => {
                    this.$state.go('admin.programmes.details.tabLayout.edit', { semesterCode: this.semesterCode, programmeCode: response.programme.code })
                },
                (response) => {
                    this.$scope.form.$setPristine()
                    this.$scope.form.$setDirty()
                    this.response = {
                        success: false,
                        statusCode: response.status,
                        text: response.data
                    }
                }
            )
        }

        resetResponse() {
            this.response = null
        }

        codeExists(value) {
            return this.ProgrammeDetails.codeUnique({ semesterCode: this.semesterCode, value: value }).$promise
        }

        nameExists(value) {
            return this.ProgrammeDetails.nameUnique({ semesterCode: this.semesterCode, value: value }).$promise
        }
    }

    angular
        .module('app')
        .controller('AdminCreateProgrammeController', AdminCreateProgrammeController)

}