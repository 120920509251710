/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    function timeFiter() {
        return function (value) {

            if (angular.isNumber(value)) {
                var totalSec = Math.round(value / 1000);
                var sec = totalSec % 60;
                var min = Math.floor(totalSec / 60) % 60;

                var result = sec + ' sec';
                if (min > 0) result = min + ' min ' + result;


                return result;
            }
            return;
        };
    }

    angular
        .module('app')
        .filter('time', timeFiter);

}