/// <reference path="../../../typings/references.d.ts" />

module _ {
    export interface LoDashStatic {
        flow(...funcs: Function[]): Function
    }
}

module PartnershipModule {

    export interface IReviewableItem {
        note
    }

    export class DataAccessFactory {

        queryParams : { [id: string]: string }
        defaultActions: { [id: string]: PartnershipsModule.DataAccess.Action }

        constructor() {
            this.queryParams = { '$inlinecount': 'allpages' }
            this.defaultActions = {
                query: { method: 'GET' },
                save: { method: 'POST' },
                update: { method: 'PUT' },
                remove: { method: 'DELETE', params: { id: '@id' } }
            }
        }

        private objectToString(object) {
            return _.reduce(object, function (result, val, key) {
                if (val) result.push(key)
                return result
            }, []).join(',')
        }

        private stringToObject(text) {
            return _.reduce(text.split(','), function (result: any, val) {
                if (val) result[val] = true
                return result
            }, {})
        }

        getRequestTransformFunctionForCheckbox(...properties) {
            return (data) => {
                if (angular.isUndefined(data)) return data
                else if (angular.isString(data)) data = JSON.parse(data)
                else data = angular.copy(data)

                angular.forEach(properties, (prop) => {
                    data[prop] = this.objectToString(data[prop])
                })

                return JSON.stringify(data)
            }
        }

        getResponseTransformFunctionForCheckbox(...properties) {
            return (data) => {
                if (angular.isString(data)) data = JSON.parse(data)

                angular.forEach(properties, (prop) => {
                    data[prop] = this.stringToObject(data[prop])
                })

                return data
            }
        }

        getTransformReqFilterProperties(...properties) {
            return (data) => {
                if (angular.isUndefined(data)) return data
                else if (angular.isString(data)) data = JSON.parse(data)
                else data = angular.copy(data)

                angular.forEach(properties, (prop) => {
                    delete data[prop]
                })

                return JSON.stringify(data)
            }
        }

        // Returns a function to allow conversion of an array of objects to an array two property 
        // objects that are required for listbox displays in metaforms
        getResponseFunctionConvertObjectArrayToListboxItems(nameProp, codeProp) {

            var nameGetter = angular.isFunction(nameProp) ? nameProp : (o) => o[nameProp]
            var codeGetter = angular.isFunction(codeProp) ? codeProp : (o) => o[codeProp]

            return function (data) {
                if (angular.isString(data)) data = JSON.parse(data)

                return _.map(data, function (item) {
                    return {
                        name: nameGetter(item),
                        code: codeGetter(item)
                    }
                })
            }
        }

        convertSnapshot(data) {
            if (angular.isString(data)) data = JSON.parse(data)

            if (!angular.isObject(data)) return data

            angular.forEach(data, (revItem: IReviewableItem) => {

                if (!angular.isObject(revItem)) return

                var note = revItem.note
                if (!angular.isObject(note) || !angular.isString(note.snapshot)) return

                note.snapshot = JSON.parse(note.snapshot)
            })
            return data
        }


        private getUrl(dataAccessUrl: string, url: string) : string{
            return `${dataAccessUrl}/${url}`
        }


        process(resources: Array<PartnershipsModule.DataAccess.Resource>) : void {

            var app = angular.module('app')
            angular.forEach(resources, (item) => {

                var actions = angular.copy(this.defaultActions)
                angular.extend(actions, item.actions || {})

                app.factory(item.name, ['$resource', 'dataAccessUrl', ($resource, dataAccessUrl) => {
                    var serverUrl = dataAccessUrl
                    if (!serverUrl) throw 'dataAccessUrl service is not defined'

                    angular.forEach(actions, (action) => {
                        if (action.url) action.url = `${serverUrl}/${action.url}`
                    })

                    return $resource(`${serverUrl}/${item.url}`, item.params || {}, actions)
                }])
            })
        }

    }
}