/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminCreateEventController {

        event
        response
        today: Date = new Date()

        private _event

        static $inject = ['$scope', '$state', 'activity', 'EventDetails', 'currencies']

        constructor(private $scope, private $state, public activity, private EventDetails, public currencies) {
        }

        formIsInvalid() {
            return angular.isUndefined(this.event) || angular.isUndefined(this.event.isConfirmed) || angular.isUndefined(this.event.bookingRequired) || this.$scope.form.$invalid
        }

        resetResponse() {
            this.response = null
        }

        clearStartDate($event) {
            this.event = this.event || {}
            this.event.startDate = null
        }

        create() {
            this.EventDetails.create({ activityId: this.activity.id }, this.event).$promise.then((response) => {
                console.log(response)
                this.$state.go('admin.events.details', { activityId: this.activity.id, eventId: response.insertedEvent.id })
            }, (response) => {
                this.$scope.response = {
                    success: false
                }
            })
        }

        get startDate() {
            return this.event && this.event.startDate && this.event.startDate.getTime();
        }

        set startDate(value) {
            this.event.startDate = new Date(value);
        }
    }

    angular
        .module('app')
        .controller('AdminCreateEventController', AdminCreateEventController)

}