/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminBoundEventsController {

        searchEvent = {
            visible: false,
            event: {
                value: null,
                included: false
            }
        }

        response

        static $inject = ['$scope', 'semester', 'programme', 'events', 'availableEvents', 'BoundEvents']

        constructor(private $scope, public semester, private programme, public events, private availableEvents, private BoundEvents) {
        }

        getAvailableEvents() {
            return this.availableEvents.filter(ae => !this.events.some(e => ae.id === e.id ) )
        }

        toggleEventIncluded(event) {
            event.included = !event.included
        }

        openSearchEvent() {
            this.searchEvent.visible = true
        }

        closeSearchEvent() {
            this.searchEvent.visible = false
        }

        resetResponse() {
            this.response = null
        }

        assign() {
            var boundEvent = angular.extend({ included: this.searchEvent.event.included }, this.searchEvent.event.value)
            this.BoundEvents.assign({ semesterCode: this.semester.code, programmeCode: this.programme.code }, angular.extend({ programme: this.programme }, boundEvent)).$promise.then((response) => {
                this.events.push(boundEvent)
                this.closeSearchEvent()
                this.response = {
                    success: true,
                    message: `assigned ${boundEvent.name}`
                }
            }, (response) => {
                this.response = {
                    success: false
                }
            })
        }

        unassign(boundEvent) {
            this.BoundEvents.unassign({ semesterCode: this.semester.code, programmeCode: this.programme.code }, angular.extend({ programme: this.programme }, boundEvent)).$promise.then((response) => {
                this.events.splice(this.events.indexOf(boundEvent), 1)
                this.response = {
                    success: true,
                    message: `unassigned ${boundEvent.name}`
                }
            }, (response) => {
                this.response = {
                    success: false
                }
            })
        }
        
    }

    angular
        .module('app')
        .controller('AdminBoundEventsController', AdminBoundEventsController)

}