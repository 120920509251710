/// <reference path="../../typings/references.d.ts" />

// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert, Pawel Szajkowski, and Ivan Brkic
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************

module PartnershipsModule {

    function MenuConfig(MenuProvider) {

        //Main menu 

        function homeTitle() {
            return 'Events' //'Home'
        }


        MenuProvider.AddMenu('anon', {
            items: [
                {
                    name: homeTitle,
                    icon: 'glyphicon glyphicon-home',
                    state: 'app.home.events',
                    param: ['storage', function (storage) {
                        return {
                            programmeCode: storage.get('programmeCode')
                        }
                    }]
                },
                {
                    name: 'Contact Us',
                    icon: 'glyphicon glyphicon-comment',
                    state: 'app.contact'
                },
                {
                    name: 'Sign in',
                    icon: 'glyphicon glyphicon-log-in',
                    state: 'app.signIn'
                }
            ]
        })

        MenuProvider.AddMenu('loggedinuser', {
            items: [
                {
                    name: homeTitle,
                    icon: 'glyphicon glyphicon-home',
                    state: 'app.home.events',
                    param: ['storage', function (storage) {
                        return {
                            programmeCode: storage.get('programmeCode')
                        }
                    }]
                },
                {
                    name: 'My Account',
                    icon: 'glyphicon glyphicon-list',
                    state: 'app.tickets',
                    linkStage: 'app.tickets'
                },
                {
                    name: 'Contact Us',
                    icon: 'glyphicon glyphicon-comment',
                    state: 'app.contact'
                },
                {
                    name: 'Sign out',
                    icon: 'glyphicon glyphicon-log-out',
                    state: 'app.signOut'
                }
            ]
        })
    }

    angular
        .module('app')
        .config(MenuConfig)

}