/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class AdminActivitiesController {

        static $inject = ['activities']

        constructor(public activities) {
        }
    }

    angular
        .module('app')
        .controller('AdminActivitiesController', AdminActivitiesController)

}