/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class ResetPasswordController {

        static $inject = ['$scope', '$state', '$dialogs', 'storage', 'Auth'];

        constructor($scope, $state, $dialogs, storage, Auth) {

            $scope.user = {};
            $scope.forms = {};

            $scope.save = function () {
                if ($scope.forms.resetPassword.$valid) {
                    Auth.resetPassword($scope.user)
                        .then(function (data) {
                            $state.go('app.home.events', { programmeCode: storage.get('programmeCode') });
                            //$state.transitionTo('app.signIn', {}, { reload: true, inherit: true, notify: true });
                        }, function (error) {
                            $dialogs.error('Error', error);
                        });
                }
            }
        }

    }

    angular
        .module("app")
        .controller("ResetPasswordController", ResetPasswordController);

}