/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    function boolFiter() {
        return function (value, whenTrue, whenFalse) {

            if (angular.isDefined(value)) {
                return value ? whenTrue : whenFalse;
            }
            return;
        };
    }

    angular
        .module('app')
        .filter('bool', boolFiter);

}