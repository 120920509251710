/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {
    'use strict';

    class ForgotPasswordController {

        static $inject = ['$scope', '$dialogs', 'Auth'];
        constructor($scope, $dialogs, Auth) {

            $scope.user = {};
            $scope.forms = {};

            $scope.reset = function () {
                if ($scope.forms.forgotPassword.$valid) {
                    Auth.forgotPassword($scope.user)
                        .then(function (data) {
                            $scope.sended = true;
                        }, function (error) {
                            $dialogs.error('Error', error);
                        });
                }
            }
        }

    }

    angular
        .module("app")
        .controller("ForgotPasswordController", ForgotPasswordController);

}