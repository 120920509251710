/// <reference path="../../typings/references.d.ts" />

module PartnershipsModule {

    AuthRequestInterceptorFactory.$inject = ['$q', 'dataAccessUrl']
    function AuthRequestInterceptorFactory($q, dataAccessUrl) {

        var token

        return {
            token: function (value) {
                if (angular.isDefined(value))
                    token = value

                return token
            },
            request: function (config) {
                if (token) {
                    if (config.url.indexOf(dataAccessUrl) == 0) {
                        config.headers.Authorization = 'Token ' + token
                    }
                }
                return config || $q.when(config)
            }
        }
    }


    angular
        .module('app')
        .factory('AuthRequestInterceptor', AuthRequestInterceptorFactory)

}