/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class AdminSemestersController {

        static $inject = ['semesters']

        constructor(public semesters) {
        }
    }

    angular
        .module('app')
        .controller('AdminSemestersController', AdminSemestersController)

}