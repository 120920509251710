/// <reference path="../../typings/references.d.ts" />
module Aifs {

    export interface OAuth {
        popup(url: string, provider: string, callback: any)
    }

    export var OAuth: OAuth
}

module PartnershipsModule {


    class TermsAndConditionsModalCtrl {

        static $inject = ['$scope', '$sce', '$uibModalInstance', 'terms']
        constructor(private $scope, private $sce, private $uibModalInstance, terms) {
            $scope.terms = $sce.trustAsHtml(terms.text)


            $scope.ok = () => {
                this.$uibModalInstance.close(terms)
            }

            $scope.cancel = () => {
                this.$uibModalInstance.dismiss('cancel')
            }
        }
    }

    class Auth {

        static defaults = {
            endpoint: '',
            signin: '/auth/signin',
            signinConfig: { response: (data) => data },
            signup: '/auth/signup',
            signupConfig: { response: (data) => data },
            signout: '/auth/signout',
            external: '/auth/external',
            validToken: '/auth/validToken',
            forgot: '/auth/pass/forgot',
            reset: '/auth/pass/reset',
            password: '/auth/pass/password',
            change: '/auth/pass/change',
            oauthSignip: '/authentication/redirect/',
            oauthSignup: '/oauth/signup',
            termsAndConditions: '',
            termsAndConditionsTmpl: '',
            termsAndConditionsAgreement: ''
        }

        private oauthInfo = {}
        private token: string

        private anonymousUser = {
            userName: '',
            role: 'PUB'
        }


        user: any = {}

        static $inject = ['$http', '$window', '$resource', '$rootScope', '$timeout', '$uibModal', '$q', 'storage', 'AuthRequestInterceptor']
        constructor(private $http: ng.IHttpService,
            private $window: ng.IWindowService,
            private $resource: ng.resource.IResourceService,
            private $rootScope: ng.IRootScopeService,
            private $timeout: ng.ITimeoutService,
            private $uibModal,
            private $q,
            private storage,
            private AuthRequestInterceptor) {

            this.changeUser(storage.get('user') || this.anonymousUser)

        }

        private changeUser(user) {

            angular.forEach(this.user, (value, name) => {
                delete this.user[name]
            })
            angular.extend(this.user, user)
            if (!user.role) {
                user.role = 'PUB'
            }

            if (this.user.token) {
                this.AuthRequestInterceptor.token(this.user.token)
                $.connection.hub.qs = 'id_token=' + this.user.token
            } else {
                this.AuthRequestInterceptor.token('')
                $.connection.hub.qs = ''
            }

            this.$rootScope.$broadcast('auth:user-change', this.user)
        }


        private loginUser(data) {

            var user = data.userInfo
            user.token = data.token
            if (user.roleDetails && user.roleDetails.hasLatestTermsAndConditions === false) {
                var modal = this.$uibModal.open({
                    templateUrl: Auth.defaults.termsAndConditionsTmpl,
                    controller: TermsAndConditionsModalCtrl,
                    size: 'lg',
                    resolve: {
                        terms: () => this.termsAndConditions(user)
                    }
                })

                return modal.result
                    .then((terms) => {
                        // success
                        var t = this.$resource(Auth.defaults.termsAndConditionsAgreement, { termsId: '@termsId', userId: '@userId' })
                        t.save({ termsId: terms.id, userId: user.userId })

                        user.roleDetails.hasLatestTermsAndConditions = true
                        user.roleDetails.agreedTermsId = terms.id

                        this.changeUser(data.userInfo)
                        this.storage.set('user', this.user)
                        return this.user

                    }, () => {
                        // error
                        return this.$q.reject({
                            type: 'notify',
                            title: 'Terms and Conditions',
                            message: 'You must accept our terms and conditions before continuing to use this web site.'
                        })
                    })
            }
            else {
                this.changeUser(data.userInfo)
                this.storage.set('user', this.user)
                return this.$q.when(this.user)
            }
        }

        private logoutUser() {
            this.storage.remove('user')
            this.changeUser(this.anonymousUser)
        }



        termsAndConditions(user) {
            if (angular.isUndefined(user)) {
                user = this.user
            }

            var terms = this.$resource(Auth.defaults.termsAndConditions, { userId: '@userId' })

            return terms.get({ userId: user.userId }).$promise
                
                
            //$http.get(defaults.termsAndConditions + role)
            //    .then(function (response) {
            //        return response.data
            //    })
        }

        isAuthorize(accessLevel, role): boolean {
            if (angular.isUndefined(role)) {
                role = this.user.role
            }
            return accessLevel.indexOf(role) >= 0
        }

        isLoggedIn(user): boolean {
            if (angular.isUndefined(user)) {
                user = this.user
            }
            return user.role != this.anonymousUser.role
        }

        private errorMessagefromJson(data, def): string {

            if (data && angular.isString(data)) {
                try {
                    data = JSON.parse(data)
                }
                catch (e) { }
            }

            if (angular.isObject(data) && data.errorMessage) {
                data = data.errorMessage;
            }

            return data || def;
        }

        signin(data) {

            //default settings
            var signinData = {
                authType: 'password'
            }
            angular.extend(signinData, data)


            return this.$http.post(Auth.defaults.endpoint + Auth.defaults.signin, signinData)
                .then((response) => {
                    // success
                    var user = Auth.defaults.signinConfig.response(response.data)
                    return this.loginUser(user)
                }, (response) => {
                    //error
                    if (response.status == 400) {
                        return this.$q.reject({
                            type: 'error',
                            title: 'Log in',
                            message: this.errorMessagefromJson(response.data, 'The email address and password you entered did not match. Please double-check and try again.')
                        })
                    } else {
                        return this.$q.reject({
                            type: 'error',
                            title: 'Log in',
                            message: this.errorMessagefromJson(response.data, 'There was a problem contacting the server. Please try again in a moment.')
                        })
                    }
                })
        }

        signup(user) {
            return this.$http.post(Auth.defaults.endpoint + Auth.defaults.signup, user)
                .then((response) => {
                    var user = Auth.defaults.signupConfig.response(response.data)
                    return this.loginUser(user)
                }, (response) => {
                    // error
                    if (response.status == 409) {
                        return this.$q.reject({
                            type: 'error',
                            title: 'Sign Up',
                            message: this.errorMessagefromJson(response.data, 'There are errors in your sign up details. Please correct any errors on the form and try again')
                        })
                    } else {
                        return this.$q.reject({
                            type: 'error',
                            title: 'Sign Up',
                            message: this.errorMessagefromJson(response.data, 'There was a problem contacting the server. Please try again in a moment.')
                        })
                    }
                })
        }

        signout(): void {
            this.logoutUser()
            //$http.get(defaults.endpoint + defaults.signout).success(function () {
            //success && success()
            //}).error(error || angular.noop)
        }

        validToken(model) {
            return this.$http.post(Auth.defaults.endpoint + Auth.defaults.validToken, model)
                .then(
                (response) => response.data,
                (response) => response.data)
        }

        forgotPassword(model) {
            return this.$http.post(Auth.defaults.endpoint + Auth.defaults.forgot, model)
                .then(
                (response) => response.data,
                (response) => this.$q.reject(response.data))
        }
        resetPassword(model) {
            return this.$http.post(Auth.defaults.endpoint + Auth.defaults.reset, model)
                .then(
                (response) => response.data,
                (response) => this.$q.reject(response.data))
        }
        changePassword(model) {
            return this.$http.post(Auth.defaults.endpoint + Auth.defaults.password, model)
                .then(
                (response) => response.data,
                (response) => this.$q.reject(response.data))
        }

        externalSignIn(token) {
            return this.$http.post(Auth.defaults.endpoint + Auth.defaults.external, { token: token })
                .then((response) => {
                    // success
                    var user = Auth.defaults.signinConfig.response(response.data)
                    return this.loginUser(user)
                }, (response) => {
                    //error
                    if (response.status == 400 || response.status == 404) {
                        return this.$q.reject({
                            type: 'error',
                            title: 'Sign In',
                            message: this.errorMessagefromJson(response.data, 'Account details not found or incorrect.')
                        })
                    } else if (response.status == 409) {
                        return this.$q.reject({
                            type: 'error',
                            title: 'Sign In',
                            message: this.errorMessagefromJson(response.data.message, 'Account details not found or incorrect.')
                        })
                    } else {
                        return this.$q.reject({
                            type: 'error',
                            title: 'Sign In',
                            message: this.errorMessagefromJson(response.data, 'There was a problem contacting the server. Please try again in a moment.')
                        })
                    }
                })
        }

        private tokenSignin(token: string) {
            var signinData = {
                authType: 'token',
                provider: 'internal',
                token: token
            }
            return this.signin(signinData)
        }

        oauthSignin(provider, success, error) {
            return this.$q((resolve, reject) => {

                Aifs.OAuth.popup(Auth.defaults.endpoint + Auth.defaults.oauthSignip + provider, provider, (er, response) => {
                    if (response.data.authType == 'internal') {
                        resolve(this.loginUser(response.data))
                    } else {
                        this.oauthInfo = response.data
                        resolve(response)
                    }
                })

            })
        }

        oauthSignup(user, token: string) {
            return this.$http.post(Auth.defaults.endpoint + Auth.defaults.oauthSignup, user)
                .then(() => {
                    // success
                    var signinData = {
                        authType: 'token',
                        provider: user.oauthProvider,
                        token: token
                    }

                    return this.signin(signinData)
                }, (response: any) => { //TODO:: replace any
                    //error
                    if (response.status == 409) {
                        return this.$q.reject({
                            type: 'error',
                            title: 'Validation error',
                            message: response.headers.message ? response.headers.message : 'Please review the form and try again'
                        })
                    } else {
                        return this.$q.reject({
                            type: 'error',
                            title: 'Connection problem',
                            message: 'Please review the form and try again after a while.'
                        })
                    }
                })
        }
        getOAuthInfo() {
            return this.oauthInfo
        }

        getToken(): string {
            return this.token
        }

    }

    class AuthProvider {
        auth: Auth
        defaults = Auth.defaults

        $get = ['$http', '$window', '$resource', '$rootScope', '$timeout', '$uibModal', '$q', 'storage', 'AuthRequestInterceptor',
            function ($http, $window, $resource, $rootScope, $timeout, $uibModal, $q, storage, AuthRequestInterceptor) {
                if (!this.auth) {
                    this.auth = new Auth($http, $window, $resource, $rootScope, $timeout, $uibModal, $q, storage, AuthRequestInterceptor)
                }

                return this.auth
            }]
    }
    angular
        .module('app')
        .provider('Auth', AuthProvider)

}