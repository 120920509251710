/// <reference path="../../../typings/references.d.ts" />
/// <reference path='locationController.ts' />

module PartnershipsModule {

    class AdminCreateLocationController extends AdminLocationController {

        form

        search = {
            parentdiv: 'mapSearchDiv',
            template: 'admin/location/mapTemplates/searchTemplate.html',
            events: {
                place_changed: (autocomplete) => this.search_changed(autocomplete)
            },
            options: {
                autocomplete: true,
                types: []
            }
        }

        static $inject = ['$scope', '$http', 'LocationDetails', '$state']

        constructor(private $scope, $http: ng.IHttpService, private LocationDetails, private $state) {
            super()

            this.location = {
                location: 0,
                latitude: null,
                longitude: null,
                address: null
            }
        }

        isGeoLocationEmpty() {
            return !this.location.latitude || !this.location.longitude
        }

        nameExists (value) {
            return this.LocationDetails.nameUnique({ value: value }).$promise
        }

        create () {
            this.LocationDetails.create(this.location).$promise.then((response) => {
                this.$state.go('admin.locations.details', { locationId: response.location.id })
            }, (response) => {
                this.response = {
                    success: false,
                    statusCode: response.status,
                    text: response.data
                }
            })
        }

        search_changed(autocomplete) {
            var place = autocomplete.getPlace()

            if (place.formatted_address) {
                this.location.address = place.formatted_address
                this.location.latitude = place.geometry.location.lat()
                this.location.longitude = place.geometry.location.lng()
                this.zoomToLocation()
            }
        }

    }

    angular
        .module('app')
        .controller('AdminCreateLocationController', AdminCreateLocationController)

}