/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class Item {
        public id
        public expiryDate

        constructor(private Auth, data) {
            angular.extend(this, data)

            if (!this.expiryDate) {
                var rightNow = new Date()
                this.expiryDate = new Date(rightNow.getTime() + 1 * 300 * 1000)
            } else if (!angular.isDate(this.expiryDate)) {
                this.expiryDate = new Date(this.expiryDate)
            }
        }

        //
        // How long is remaining for the reservation
        //
        remainingTime() {
            // for the time being, just return the expiryDate
            if (!this.expiryDate) {
                return 0
            }

            var rightNow = new Date()
            var result = this.expiryDate.getTime() - rightNow.getTime()

            return result > 0 ? result : 0
        }

        isExpired() {
            var rightNow = new Date()

            console.log('exp: Expiry date is ' + this.expiryDate)
            if (this.expiryDate < rightNow) {
                console.log('Item ' + this.id + ' has expired as of ' + rightNow)
                return true
            }

            console.log('item has not expired')
            return false
        }

        isBooked (value) {
            return this.itemsIsBooked(this.id, value);
        }

        private itemsIsBooked(id, value) {
            var result = value ? true : false

            if (this.Auth.isLoggedIn()) {
                if (!this.Auth.user.bookedEvents)
                    this.Auth.user.bookedEvents = []

                var events = this.Auth.user.bookedEvents
                for (var i = 0; i < events.length; i++) {
                    if (events[i] == id) {
                        if (angular.isUndefined(value)) {
                            result = true
                        } else if (!value) {
                            events.splice(i, 1)
                        }

                        return result
                    }
                }
                if (value) {
                    events.push(id)
                }
            }
            return result
        }

        public serialize() {
            // create a copy of this without Auth
            return _.omit(this, 'Auth')
        }

    }

    class ShoppingCart {
        static $inject = ['$rootScope', '$timeout', 'storage', 'Auth']

        constructor(private $rootScope, private $timeout, private storage, private Auth) {
            console.log('instantiating ShoppingCartProvider')
            this.restore()
            console.log('restored')
        }

        private basket

        brodcastChanges() {
            this.$rootScope.$broadcast('shoppingCart:change')
        }

        storeChanges() {
            this.brodcastChanges()
            var basketCopy = _.map(this.basket, (item : Item) => item.serialize())

            this.storage.set('shoppingCartItems', basketCopy)
        }

        restore() {
            this.basket = []
            var items = this.storage.get('shoppingCartItems')
            if (angular.isArray(items)) {
                for (var i = 0; i < items.length; i++) {
                    this.addItem(items[i])
                }
            }
        }

        //
        // Add an item to the cart
        //
        addItem(data) {
            var newItem = new Item(this.Auth, data)

            this.basket.push(newItem)
            this.storeChanges()
        }

        // 
        // Remove an item from the cart
        //
        removeItem(item: Item) {
            console.log(`removing item ${item.id} from shopping cart`)

            for (var i = 0; i < this.basket.length; i++) {
                if (this.basket[i].id == item.id) {

                    if (this.basket[i].$timeout) {
                        this.$timeout.cancel(this.basket[i].$timeout)
                        delete this.basket[i].$timeout
                    }

                    this.basket.splice(i, 1)
                    this.storeChanges()

                    console.log(`new basket value is ${ this.getBasketCost() } item count is ${ this.basket.length }`)
                    break
                }
            }
        }

        //
        // Return the basket items
        //
        public items() {
            // TODO: should we return copy just in case ? 
            return this.basket
        }

        // 
        // Determine the basket cost
        //
        public getBasketCost() {
            console.log('get basket cost')

            var cost = 0
            for (var i = 0; i < this.basket.length; i++) {
                cost += this.basket[i].cost
            }

            return cost
        }

        //
        // Is the passed item.id present in the basket already?
        //
        public itemInBasket(id) {
            for (var i = 0; i < this.basket.length; i++) {
                if (this.basket[i].id === id) {
                    return true
                }
            }
            return false
        }

        public clear() {
            while (this.basket.length > 0) {
                this.basket.pop()
            }
            this.storeChanges()
        }
    }

    class ShoppingCartProvider {
        shoppingCart: ShoppingCart

        $get = ['$rootScope', '$timeout', 'storage', 'Auth',
            ($rootScope, $timeout, storage, Auth) => {
                if (!this.shoppingCart)
                    this.shoppingCart = new ShoppingCart($rootScope, $timeout, storage, Auth)

                return this.shoppingCart
            }]
    }

    //
    // Hook this service up with the angular app.
    //
    angular
        .module('app')
        .provider('ShoppingCartService', ShoppingCartProvider)

}