/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class LocationZoomController {

        locationController

        zoomToLocation = () => { this.locationController.zoomToLocation() }

        static $inject = ['$scope']

        constructor($scope) {
            this.locationController = $scope.$parent.$parent.ctrl
        }
    }

    angular
        .module('app')
        .controller('zoomController', LocationZoomController)
}
