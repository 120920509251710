/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class BookingResultController {

        static $inject = ['$scope', 'Auth', 'result', 'ShoppingCartService'];
        constructor($scope, Auth, result, ShoppingCartService) {

            $scope.isSignIn = Auth.isLoggedIn();

            $scope.result = result;


            if (result.success) {

                if (result.reference.includes(' ')) {
                    console.log('we contain a space')
                    result.reference = result.reference.substring(25, result.reference.length)
                }

                //TODO clean basket
                ShoppingCartService.clear();

                // it will be better if in ShoppingCartService we will keep 
                // orderId and when we restore basket from local store we will ask servise
                // if payment is finish sucessfull and if so then clean basket
            }
        }
    }

    angular
        .module("app")
        .controller("BookingResultController", BookingResultController);

}