/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class eventsActivityController {

        static $inject = ['$scope', 'events', 'activityId']

        constructor(private $scope, public events, public activityId) {

            $scope.$watch(this.getElementDimensionWidth, function (newValue, oldValue) {
                angular.element('.progressbar-front-text').width(newValue)
            }, true)
        }

        getElementDimensionWidth() {
            var element = angular.element('.progressbar-back-text')
            return element ? element.width() : 0
        }
    }

    angular
        .module('app')
        .controller('AdminEventsActivityController', eventsActivityController)

}