/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    var promise;

    function programmeProvider($resource, $q, Auth, storage, dataAccessUrl) {

        function downloadProgramme(programmeCode) {
            return $resource(dataAccessUrl + '/programme/:programmeCode')
                .get({ programmeCode: programmeCode })
                .$promise;
        }

        this.get = function (programmeCode) {

            // user programme have bigest priority
            if (Auth.isLoggedIn() && Auth.user.programme)
                programmeCode = Auth.user.programme;


            // if programmeCode is not define try to get from local store. Otherwise save programmeCode
            if (!programmeCode)
                programmeCode = storage.get('programmeCode');
            else
                storage.set('programmeCode', programmeCode);


            // TODO: remove - temporary fix for RISS15 programme merge
            if (programmeCode.indexOf('RISS15A') === 0)
                programmeCode = 'RISS15';


            // programmeCode is unknown - reject !
            if (!programmeCode)
                return $q.reject();



            // download promise if is a first time
            if (!promise) {
                return promise = downloadProgramme(programmeCode);
            } else {
                return promise.then(function (programme) {
                    if (programme.code != programmeCode)
                        return downloadProgramme(programmeCode)
                    else
                        return programme;
                });
            }
        }
    }

    function programmeFactory($resource, $q, Auth, storage, dataAccessUrl) {

        console.log('programmeFactory ctor');

        return new programmeProvider($resource, $q, Auth, storage, dataAccessUrl);
    }

    angular
        .module('app')
        .factory('Programme', programmeFactory);

}