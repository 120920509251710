/// <reference path="../../typings/references.d.ts" />

// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert, Pawel Szajkowski, and Ivan Brkic
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************

module PartnershipsModule {

    function MenuConfig(MenuProvider) {

        //Main menu 

        function homeTitle() {
            return 'Home'
        }


        MenuProvider.AddMenu('admin-anon', 'admin', {
            items: [
                {
                    name: homeTitle,
                    icon: 'glyphicon glyphicon-home',
                    state: 'admin.home'
                },
                {
                    name: 'Log in',
                    icon: 'glyphicon glyphicon-log-in',
                    action: ['adalAuthenticationService', function (adalAuthenticationService) {
                        adalAuthenticationService.login()
                    }]
                }
            ]
        })

        MenuProvider.AddMenu('admin-loggedin', 'admin', {
            items: [
                {
                    name: homeTitle,
                    icon: 'glyphicon glyphicon-home',
                    state: 'admin.home'
                },
                {
                    name: 'Semesters',
                    icon: 'fa fa-calendar',
                    state: 'admin.semesters.list'
                },
                {
                    name: 'Locations',
                    icon: 'fa fa-map-marker',
                    state: 'admin.locations.list'
                },
                {
                    name: 'Activities',
                    icon: 'fa fa-fort-awesome',
                    state: 'admin.activities.list'
                },
                {
                    name: 'Students',
                    icon: 'fa fa-user',
                    state: 'admin.attendees.list'
                },
                
                {
                    name: 'Log out',
                    icon: 'glyphicon glyphicon-log-out',
                    action: ['adalAuthenticationService', function (adalAuthenticationService) {
                        adalAuthenticationService.logOut()
                    }]
                }
            ]
        })
    }

    angular
        .module('app')
        .config(MenuConfig)

}