/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class EventlistController {
        constructor($scope, $state, $stateParams, eventlist, programme) {
            if (eventlist) {
                $scope.noEventsFound = eventlist.length == 0;
                $scope.eventlist = eventlist;
            } else {
                $scope.noEventsFound = true;
            }

            if (programme) {
                $scope.programme = programme;
                $scope.programmeFound = true;

                if (programme.code != $stateParams.programmeCode) {
                    $stateParams.programmeCode = programme.code;
                    $state.transitionTo($state.current, $stateParams, { reload: true, inherit: false, notify: true });
                }

            } else {
                $scope.programmeFound = false;
            }
        }
    }

    angular
        .module("app")
        .controller("EventlistController", EventlistController);

}