/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    function basketFactory($resource, dataAccessUrl) {

        console.log('basketFactory ctor');

        return $resource(dataAccessUrl + '/checkout/basket', {}, {
            checkout: { method: 'POST', url: dataAccessUrl + '/checkout/basket' }
        });
    }

    angular
        .module('app')
        .factory('Basket', basketFactory);

}