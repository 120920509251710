/// <reference path="../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminAttendeesController {
        programmes
        attendees


        firstName: string;
        lastName: string;   
        email: string;
        semester: {
            code: string
        }
        programme: {
            id: number
        }

        static $inject = ['Attendees', 'Programmes', 'semesters']

        constructor(private Attendees, private Programmes, public semesters) {
        }


        populateProgrammes() {
            if(this.semester) {
                this.programmes = this.Programmes.list({ semesterCode: this.semester.code });
                this.programme = undefined;
            }
        }

        search() {
            this.attendees = this.Attendees.list({ 
                firstName: this.firstName || undefined,
                lastName: this.lastName || undefined,
                email: this.email || undefined,
                semesterCode: this.semester && this.semester.code,
                programmeId: this.semester && this.programme && this.programme.id
            });
        }

        clear() {
            this.firstName = undefined;
            this.lastName = undefined;
            this.email = undefined;
            this.semester = undefined;
            this.programme = undefined;
            this.attendees = undefined;
        }
    }

    angular
        .module('app')
        .controller('AdminAttendeesController', AdminAttendeesController)

}
