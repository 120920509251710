/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    angular
        .module('app')
        .constant('secureTrading',
        {
            siteReference: 'aifspartnerships56850'
        });

}