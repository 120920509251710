/// <reference path="../../../../typings/references.d.ts" />
/// <reference path="../dataaccessfactory.ts" />

module PartnershipModule {

	// Test

	class OurDate {

		parsedDate
		parsedDateToJson
		momentToDate
		momentToDateToJson
		momentToUtcDate
		momentToUtcDateToJson
		momentParsedDateToDate
		momentParsedDateToDateToJson

		constructor(public recieved) {
			this.parsedDate = new Date(recieved)
			this.parsedDateToJson = this.parsedDate.toJSON()
			this.momentToDate = moment(recieved).toDate()
			this.momentToDateToJson = this.momentToDate.toJSON()
			this.momentToUtcDate = moment(recieved).utc().toDate()
			this.momentToUtcDateToJson = this.momentToUtcDate.toJSON()
			this.momentParsedDateToDate = moment(new Date(recieved)).toDate()
			this.momentParsedDateToDateToJson = this.momentParsedDateToDate.toJSON()
		}

	}

	// Test \END

	class EventsFactory extends DataAccessFactory {

		constructor() {
			super()

			this.defaultActions = {
				query: { method: 'GET', params: this.queryParams }
			}

			this.initialize()
		} 


		initialize() {
			var resources: Array<PartnershipsModule.DataAccess.Resource> = [
				{
					name: 'Events',
					url: 'admin/semesters/:semesterCode/events',
					actions: {
						list: {
							method: 'GET',
							isArray: true
						},
						available: {
							method: 'GET',
							url: 'admin/semesters/:semesterCode/available-events',
							isArray: true
						},
						fromActivity: {
							method: 'GET',
							url: 'admin/activity/:activityId/events',
							isArray: true
						}
					}
				},
				{
					name: 'BoundEvents',
					url: 'admin/semesters/:semesterCode/programmes/:programmeCode/events',
					actions: {
						list: {
							method: 'GET',
							isArray: true
						},
						assign: {
							method: 'PUT',
							url: 'admin/semesters/:semesterCode/programmes/:programmeCode/assign-event'
						},
						unassign: {
							method: 'PUT',
							url: 'admin/semesters/:semesterCode/programmes/:programmeCode/unassign-event'
						}
					}
				},
				{
					name: 'EventDetails',
					url: 'admin/activity/:activityId/events/:eventId',
					params: {
						activityId: '@activityId',
						eventId: '@id'
					},
					actions: {
						query: {
							method: 'GET',
							transformResponse: (data) => {
								if (angular.isString(data)) data = JSON.parse(data)

                                data.startDate = data.startDate ? moment(data.startDate).toDate() : null
								data.showFrom = moment(data.showFrom).toDate()
								data.showUntil = moment(data.showUntil).toDate()
								data.isPast = function () {
									return this.startDate && this.startDate < new Date()
								}
								return data
							}
						},
						update: {
							method: 'PUT'
						},
						create: {
							url: 'admin/activity/:activityId/events/create',
							method: 'POST'
						},
						uploadDocument: {
							url: 'admin/activity/:activityId/events/:eventId/document',
							method: 'POST'
						},
						deleteDocument: {
							url: 'admin/activity/:activityId/events/:eventId/document',
							method: 'DELETE'
						}
                    }
                },
                {
                    name: 'Currencies',
                    url: 'admin/currencies',
                    actions: {
                        list: {
                            method: 'GET',
                            isArray: true
                        }
                    }
                }
			]


			this.process(resources)
		}
	}

	new EventsFactory()
}

