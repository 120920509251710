/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminProgrammeLayoutController {

        constructor(public programmeCode) {
        }
    }

    angular
        .module('app')
        .controller('AdminProgrammeLayoutController', AdminProgrammeLayoutController)

}