/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminCreateSemesterController {

        semester
        today : Date = new Date()
        response

        static $inject = ['$scope', 'SemesterDetails', '$state']

        constructor(private $scope, private SemesterDetails, private $state) {
        }

        codeExists (value) {
            return this.SemesterDetails.codeUnique({ value: value }).$promise
        }

        resetResponse() {
            this.response = null
        }

        nameExists = function (value) {
            return this.SemesterDetails.nameUnique({ value: value }).$promise
        }

        create () {
            this.SemesterDetails.create(this.semester).$promise.then((response) => {
                console.log(response)
                this.$state.go('admin.semesters.details.tabLayout.edit', { semesterCode: this.semester.code })
            }, (response) => {
                this.$scope.response = {
                    success: false
                }
            })
        }
    }

    angular
        .module('app')
        .controller('AdminCreateSemesterController', AdminCreateSemesterController)

}