/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminAttendeeLayoutController {

        constructor() {
        }
    }

    angular
        .module('app')
        .controller('AdminAttendeeLayoutController', AdminAttendeeLayoutController)

}