/// <reference path="../../typings/references.d.ts" />

module PartnershipsModule {

    class AccessLevels {

        has(level) {
            return Object.keys(this).indexOf(level) >= 0
        }

        firstMatch(obj) {
            var keys = Object.keys(this)

            // reorder by priority
            return _.chain(obj)
                .filter((item, key) => keys.indexOf(key) >= 0)
                .sortBy((item: any) => angular.isDefined(item.priority) ? -item.priority : 0)
                .first()
                .value()
        }
    }

    class AccessLevelsManager {
        public userLevels: AccessLevels
        private userLevelsPromise

        constructor(private $injector, private $q, private levels) {
            this.userLevels = new AccessLevels()
        }


        apply(role) {
            var promises = []

            //clean old roles
            angular.forEach(this.userLevels, (level, name: string) => {
                delete this.userLevels[name]
            })

            angular.forEach(this.levels, (level, name: string) => {

                // roles not included
                if (angular.isArray(level.roles)) {
                    if (level.roles.indexOf(role) < 0)
                        return
                } else if (angular.isString(level.roles)) {
                    if (level.roles != role)
                        return
                }

                // check condition
                if (angular.isDefined(level.condition)) {
                    if (angular.isFunction(level.condition) || angular.isArray(level.condition)) {
                        var promise = this.$q
                            .when(this.$injector.invoke(level.condition, null, {}))
                            .then((value) => {
                                if (value) {
                                    this.userLevels[name] = level
                                }
                            })
                        promises.push(promise)
                        return
                    } else if (!level.condition) {
                        return
                    }
                }

                this.userLevels[name] = level
            })

            return this.userLevelsPromise = this.$q
                .all(promises)
                .then(() => this.userLevels)
        }

        promise() {
            return this.userLevelsPromise
        }
    }


    class AccessLevelProvider implements PartnershipsModule.AccessLevelProvider {
        private levels = {}
        private accessLevelsManager: AccessLevelsManager

        accesslevel(name: PartnershipsModule.IAccessLevel, level: PartnershipsModule.ILevel) {
            this.levels[<string> name] = level
        }

        $get = ['$injector', '$q', ($injector, $q) => {
            if (!this.accessLevelsManager)
                this.accessLevelsManager = new AccessLevelsManager($injector, $q, this.levels)

            return this.accessLevelsManager
        }]
    }

    angular
        .module('app')
        .provider('AccessLevel', AccessLevelProvider)

}