/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class View {

        default: any;

        constructor(def) {

            var defRouting = {
                controller: angular.noop,
                template: ''
            };

            if (angular.isObject(def.default))
                angular.extend(defRouting, def.default);

            for (var key in def) {
                if (key === 'controllerAs') {
                    this[key] = def[key];
                } else {
                    this[key] = angular.extend(angular.copy(defRouting), def[key]);
                }
            }
        }

        controllerProvider = ['AccessLevel', this.controllerProviderImpl.bind(this)]
        templateProvider = ['AccessLevel', '$http', '$templateCache', '$q', this.templateProviderImpl.bind(this)];

        private getRouting(userLevels) {
            var result = userLevels.firstMatch(this);

            if (!result && angular.isDefined(this.default))
                result = this.default;

            // TODO redirect - don't have access 
            return result;
        }

        private controllerProviderImpl(AccessLevel) {
            var routing = this.getRouting(AccessLevel.userLevels);
            return routing.controller;
        }

        private templateProviderImpl(AccessLevel, $http, $templateCache, $q) {
            return $q((resolve, reject) => {

                AccessLevel.promise().then((userLevels) => {
                    var routing = this.getRouting(userLevels);

                    if (angular.isUndefined(routing)) {
                        reject('Don\'t have access.');
                    } else {
                        var url = routing.template;
                        if (!url) {
                            resolve(url);
                        } else {
                            $http.get(url, { cache: $templateCache })
                                .success((data) => {
                                    resolve(data);
                                });
                        }
                    }
                });
            });
        }
    }






    class RoutingProvider implements ng.IServiceProvider {

        static $inject = ['$stateProvider'];
        constructor(private $stateProvider) {

        }

        addState(state, namePrefix) {

            if (angular.isArray(state)) {
                for (var i = 0; i < state.length; i++) {
                    this.addState(state[i], namePrefix);
                }
                return;
            }


            var stateName = state.name;
            if (namePrefix)
                stateName = namePrefix + '.' + stateName;

            if (!state.resolve)
                state.resolve = {};

            var views = state.views;
            if (angular.isObject(views)) {
                for (var key in views) {
                    views[key] = new View(views[key]);
                }
            }

            this.$stateProvider.state(stateName, state);

            var states = state.states;
            if (angular.isArray(states)) {
                for (var j = 0; j < states.length; j++) {
                    this.addState(states[j], stateName);
                }
            }
        }


        $get = [function () {
            return {};
        }];
    }

    angular
        .module('app')
        .provider('Routing', RoutingProvider);
}