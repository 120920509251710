/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class MenuItem {
        name: string;
        param;

        constructor(private $injector) {
        }

        private getValue(obj) {
            if (angular.isFunction(obj) || angular.isArray(obj)) {
                return this.$injector.invoke(obj, null, {});
            } else {
                return obj;
            }
        }

        display() {
            return this.getValue(this.name);
        }

        params() {
            return this.getValue(this.param);
        }
    }




    class MenuProvider implements ng.IServiceProvider {

        private static menus = {};

        static $inject = ['$injector'];
        constructor(private $injector) {
        }

        private extendMenuItems(menu) {
            if (menu.items) {
                for (var i = 0; i < menu.items.length; i++) {
                    menu.items[i] = angular.extend(new MenuItem(this.$injector), menu.items[i]);
                    this.extendMenuItems(menu.items[i]);
                }
            }
        }

        AddMenu(accessLevel, name, menu) {
            if (angular.isObject(name)) {
                menu = name;
                name = '';
            }

            this.extendMenuItems(menu);

            var menuGroup = MenuProvider.menus[name] || (MenuProvider.menus[name] = {});
            menuGroup[accessLevel] = menu;
        }

        $get = ['AccessLevel', function (AccessLevel) {

            return function getMenu(name) {
                name = name || '';

                var menuGroup = MenuProvider.menus[name];
                if (menuGroup) {
                    return AccessLevel.userLevels.firstMatch(menuGroup);
                }

                return [];
            }
        }];
    }

    angular
        .module('app')
        .provider('Menu', MenuProvider);

}