/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    //source: https://gist.github.com/jeffjohnson9046/9470800

    angular
        .module('app')
        .filter('percentage', ['$filter', function ($filter) {
        return function (input, decimals) {
            return $filter('number')(input * 100, decimals) + '%'
        }
    }])

}