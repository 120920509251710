/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    export abstract class AdminLocationController {

        private defaultZoom: number = 19

        response

        map = {
            center: {
                latitude: 54.6,
                longitude: -4.6
            },
            zoom: 5
        }

        location

        constructor() { }

        resetResponse() {
            this.response = null
        }

        zoomToLocation(location = this.location, zoom: number = this.defaultZoom): void {
            this.map.center.latitude = location.latitude
            this.map.center.longitude = location.longitude
            this.map.zoom = zoom
        }

    }
}