/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    angular
        .module('app')
        .config(function ($httpProvider, AuthProvider, dataAccessUrl: string) {
            var server = dataAccessUrl

            AuthProvider.defaults.signin = `${server}/account/sign-in`
            AuthProvider.defaults.signinConfig.response = function (user) {
                user.userInfo.role = 'USR'
                return user
            }
            AuthProvider.defaults.signup = `${server}/account/create`
            AuthProvider.defaults.signupConfig.response = function (user) {
                user.userInfo.role = 'USR'
                return user
            }
            AuthProvider.defaults.forgot = `${server}/account/forgotten-password`
            AuthProvider.defaults.reset = `${server}/account/reset-password`
            AuthProvider.defaults.external = `${server}/account/external`



            AuthProvider.defaults.password = `${server}/users/password`



            AuthProvider.defaults.oauthSignip = '/authentication/redirect/'


            AuthProvider.defaults.oauthSignup = '//uat.apps.aupairweb.co.uk:9001/registration/register'
            AuthProvider.defaults.termsAndCondition = '//uat.apps.aupairweb.co.uk:9000/users/terms/'
            AuthProvider.defaults.termsAndConditionTmpl = 'templates/user/termsAndConditions.html'
            AuthProvider.defaults.termsAndConditionAgreement = '//uat.apps.aupairweb.co.uk:9000/users/:userId/agreement/:termsId'

            $httpProvider.interceptors.push('AuthRequestInterceptor')
        })

}