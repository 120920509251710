/// <reference path="../../../../typings/references.d.ts" />
/// <reference path="../dataaccessfactory.ts" />

module PartnershipModule {

    class LocationFactory extends DataAccessFactory {

        constructor() {
            super()

            this.defaultActions = {
                query: { method: 'GET', params: this.queryParams }
            }

            this.initialize()
        }


        initialize() {
            var resources: Array<PartnershipsModule.DataAccess.Resource> = [
                {
                    name: 'Locations',
                    url: 'admin/locations',
                    actions: {
                        list: {
                            method: 'GET',
                            isArray: true
                        }
                    }
                },
                {
                    name: 'LocationDetails',
                    url: 'admin/locations/:locationId',
                    params: {
                        locationId: '@id'
                    },
                    actions: {
                        query: {
                            method: 'GET'
                        },
                        update: {
                            method: 'PUT'
                        },
                        create: {
                            url: 'admin/locations/create',
                            method: 'POST'
                        },
                        nameUnique: {
                            url: 'admin/location-name-unique',
                            method: 'POST'
                        }
                    }
                }
            ]


            this.process(resources)
        }
    }

    new LocationFactory()
}

