/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class ShoppingCartController {

        static $inject = ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$dialogs', 'Basket', 'Auth', 'ShoppingCartService', 'secureTrading', 'programme'];
        constructor ($rootScope, $scope, $state, $stateParams, $timeout, $dialogs, Basket, Auth, ShoppingCartService, secureTrading, programme) {

            $scope.secureTrading = secureTrading;
            $scope.basketItems = ShoppingCartService.items();

            $scope.basketTotalValue = function () {
                return ShoppingCartService.getBasketCost();
            };

            $scope.removeFromBasket = function (item) {
                ShoppingCartService.removeItem(item);
            };


            $scope.isSignIn = Auth.isLoggedIn();
            $scope.user = Auth.user;

            $scope.$on('shoppingCart:change', function () {

            });

            $scope.payment = { currency: { currencySymbol:'£'} }

            $scope.$watch(() => $scope.basketItems, () => {
                $scope.payment.currency = $scope.basketItems[0].currency
            })

            $scope.forms = {}

            $scope.beginPayment = function () {

                $rootScope.$broadcast('loader:start');


                Basket.checkout({
                    attendeeId: Auth.user.id,
                    programme: programme.code,
                    items: _.map($scope.basketItems, function (item : any) { return item.id; })
                }).$promise.then(function (basket) {
                        if (basket.amountDue > 0) {
                            $scope.payment = {
                                currency: basket.currency,
                                amount: basket.amountDue / 100,
                                reference: basket.accountCode + ' ' + basket.bookingReference,
                                basketid: basket.id
                            };

                            // need to be executed in next digest, after payment values hqave been applied
                            $timeout(function () {
                                $scope.forms.payment.submit();
                            });
                        } else {
                            $state.go('^.bookSuccess', { reference: basket.bookingReference });
                        }
                    }, function (response) {
                        $rootScope.$broadcast('loader:stop');

                        if (response.status == 409) {

                            _.forEach(response.data, function (obj: any) {
                                var item: any = _.find($scope.basketItems, { id: obj.id });

                                if (obj.status == 'S') {
                                    item.isSoldOut = true;
                                } else {
                                    item.isBooked(true);
                                }
                            });

                            $dialogs.notify('Unable to Process Payment', 'You are unable to purchase one or more of the events in your basket. Please remove the highlighted items before completing your payment');
                        } else {
                            $dialogs.error('Error', 'Something wents wrong :( Please try again after few seconds');
                        }

                    });
            }
        }
    }

    angular
        .module("app")
        .controller("ShoppingCartController", ShoppingCartController);

}