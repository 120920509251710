/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminEventDetailsController {

        response
        today: Date = new Date()

        private _event
        public file

        static $inject = ['$scope', 'event', 'activity', 'currencies', 'EventDetails', 'Upload']

        constructor(private $scope, public event, public activity, public currencies, public EventDetails, public Upload) {
            this._event = angular.copy(event)
        }

        resetResponse() {
            this.response = null
        }

        clearStartDate($event) {
            this.event.startDate = null
            if (!this.$scope.form.$dirty) this.$scope.form.$setDirty()
        }

        uploadDocument() {
            if(this.file) {

            this.EventDetails
                .uploadDocument({ activityId: this.activity.id, eventId: this.event.id }, {fileName: this.file.name})
                .$promise
                .then((response) => {
                    var image = this.file.name;
                    var key = response.filePattern + image.slice(image.lastIndexOf('.'));

                    return this.Upload.upload({
                        url: 'https://' + response.bucket + '.s3.amazonaws.com/',
                        method: 'POST',
                        fields: {
                            key: key,
                            AWSAccessKeyId: response.awsAccessKeyId,
                            acl: response.acl,
                            policy: response.policy,
                            signature: response.signature,
                            'Content-Type': this.file.type
                        },
                        file: this.file
                    }).then(() => {
                        this.event = this.EventDetails.query({ activityId: this.activity.id, eventId: this.event.id });
                    });
                    
                });
            }
        }

        deleteDocument() {
            this.EventDetails
                .deleteDocument({ activityId: this.activity.id, eventId: this.event.id })
                .$promise
                .then(() => {
                    this.event.documentUrl = undefined;
                });

        }

        save() {
            this.event.$update({ activityId: this.activity.id }).then((response) => {
                this.$scope.form.$setPristine()
                this._event = angular.copy(this.event)

                this.response = {
                    success: true
                }
            }, (response) => {
                this.$scope.form.$setPristine()
                this.$scope.form.$setDirty()
                this.$scope.response = {
                    success: false
                }
            })
        }

        get startDate() {
            return this.event && this.event.startDate && this.event.startDate.getTime();
        }

        set startDate(value) {
            this.event.startDate = new Date(value);
        }
    }

    angular
        .module('app')
        .controller('AdminEventDetailsController', AdminEventDetailsController)

}