/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    function link(scope, elem) {
        scope.$on('loader:start', function () {
            elem.show();
        });

        scope.$on('loader:stop', function () {
            elem.hide();
        });
    }

    angular
        .module('app')
        .directive('aifsLoader', function () {
            return {
                restrict: 'A',
                link: link
            };
        });
}