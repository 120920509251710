/// <reference path="../../../../typings/references.d.ts" />
/// <reference path="../dataaccessfactory.ts" />

module PartnershipModule {

    class AttendeesFactory extends DataAccessFactory {

        constructor() {
            super()

            this.defaultActions = {
                query: { method: 'GET', params: this.queryParams }
            }

            this.initialize()
        }


        initialize() {
            var resources: Array<PartnershipsModule.DataAccess.Resource> = [
                {
                    name: 'Attendees',
                    url: 'admin/attendees',
                    actions: {
                        list: {
                            method: 'GET',
                            isArray: true
                        }
                    }
                },
                {
                    name: 'AttendeesDetails',
                    url: 'admin/attendees/:attendeeId',
                    params: {
                        attendeeId: '@id'
                    },
                    actions: {
                        query: {
                            method: 'GET'
                        }//,
                        // update: {
                        //     method: 'PUT'
                        // }
                    }
                }
            ]


            this.process(resources)
        }
    }

    new AttendeesFactory()
}