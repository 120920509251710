/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    function eventFactory($resource, dataAccessUrl) {

        console.log('eventFactory ctor');

        return $resource(dataAccessUrl + '/programme/:programmeCode/events/:id', {}, {
            query: { method: 'GET', url: dataAccessUrl + '/programme/:programmeCode/events', isArray: true },
            getEvent: { method: 'GET', url: dataAccessUrl + '/programme/:programmeCode/events/:id', isArray: false },
            getRelated: { method: 'GET', url: dataAccessUrl + '/programme/:programmeCode/events/:id/related', isArray: true }
        });
    }

    angular
        .module('app')
        .factory('Event', eventFactory);

}