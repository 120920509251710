/// <reference path="../../../../typings/references.d.ts" />
/// <reference path="../dataaccessfactory.ts" />

module PartnershipModule {

    class TestFactory extends DataAccessFactory {

        constructor() {
            super()

            this.defaultActions = {
                query: { method: 'GET', params: this.queryParams }
            }

            this.initialize()
        }


        initialize() {
            var resources: Array<PartnershipsModule.DataAccess.Resource> = [
                {
                    name: 'Test',
                    url: 'admin/test',
                    actions: {
                        test: {
                            method: 'GET'
                        }
                    }
                }
            ]


            this.process(resources)
        }
    }

    new TestFactory()
}

