/// <reference path="../../../../typings/references.d.ts" />
/// <reference path="../dataaccessfactory.ts" />

module PartnershipModule {

    class ProgrammeFactory extends DataAccessFactory {

        constructor() {
            super()

            this.defaultActions = {
                query: { method: 'GET', params: this.queryParams }
            }

            this.initialize()
        }


        initialize() {
            var resources: Array<PartnershipsModule.DataAccess.Resource> = [
                {
                    name: 'Programmes',
                    url: 'admin/semesters/:semesterCode/programmes',
                    actions: {
                        list: {
                            method: 'GET',
                            isArray: true
                        }
                    }
                },
                {
                    name: 'ProgrammeDetails',
                    url: 'admin/semesters/:semesterCode/programmes/:programmeCode',
                    params: {
                        semesterCode: '@semesterCode'
                    },
                    actions: {
                        query: {
                            method: 'GET',
                            params: {
                                programmeCode: '@code'
                            }
                        },
                        codeUnique: {
                            url: 'admin/semesters/:semesterCode/programme-code-unique',
                            method: 'POST'
                        },
                        nameUnique: {
                            method: 'POST',
                            url: 'admin/semesters/:semesterCode/programme-name-unique'
                        },
                        create: {
                            url: 'admin/semesters/:semesterCode/programmes/create',
                            method: 'POST'
                        },
                        update: {
                            method: 'PUT',
                            params: {
                                programmeCode: '@code'
                            }
                        }
                    }
                },
                {
                    name: 'AccountCodes',
                    url: 'admin/accountcodes',
                    actions: {
                        list: {
                            method: 'GET',
                            isArray: true
                        }
                    }
                }
            ]


            this.process(resources)
        }
    }

    new ProgrammeFactory()
}

