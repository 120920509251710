/// <reference path="../../typings/references.d.ts" />
/// <reference path="../admin/location/locationController.ts" />

module PartnershipsModule {

    class EventDetailsController extends AdminLocationController {

        static $inject = ['$scope', '$state', '$stateParams', 'ShoppingCartService', 'event', 'programme', 'related']

        constructor($scope, $state, $stateParams, private ShoppingCartService, public event, programme, public related) {
            super()

            if (programme.code != $stateParams.programmeCode) {
                $stateParams.programmeCode = programme.code
                $state.transitionTo($state.current, $stateParams, { reload: true, inherit: false, notify: true })
            }

            this.zoomToLocation(event.location, 17)

            $scope.addToBasket = function (event) {
                //console.log('adding item to basket for event id ' + event.id);
                ShoppingCartService.addItem(event)
            }

            $scope.removeFromBasket = function (event) {
                //console.log('removing item from basket for event id ' + event.id);
                ShoppingCartService.removeItem(event)
            }


            $("html, body").animate({ scrollTop: 0 }, 200)
        }

        InBasketAlready() {
            return this.ShoppingCartService.itemInBasket(this.event.id)
        }

        IsBooked() {
            return this.ShoppingCartService.itemsIsBooked(this.event.id)
        }

        tixRemain() {
            return this.event.allocation - this.event.sold
        }
    }

    angular
        .module("app")
        .controller("EventDetailsController", EventDetailsController)
}