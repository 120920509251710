/// <reference path="../../../typings/references.d.ts" />
module PartnershipsModule {

    class UserController {

        static $inject = ['$scope', 'Auth', 'storage'];

        constructor($scope, Auth, storage) {
            var currentDate = new Date();
            $scope.year = currentDate.getFullYear();
        }
    }

    angular
        .module("app")
        .controller("UserController", UserController);

}