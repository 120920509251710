/// <reference path="../../../typings/references.d.ts" />
/// <reference path='locationController.ts' />

module PartnershipsModule {

    class AdminLocationDetailsController extends AdminLocationController {

        private _location

        search = {
            parentdiv: 'mapSearchDiv',
            template: 'admin/location/mapTemplates/searchTemplate.html',
            events: {
                place_changed: (autocomplete) => this.search_changed(autocomplete)
            },
            options: {
                autocomplete: true,
                types: []
            }
        }

        static $inject = ['$scope', 'LocationDetails', 'location']

        constructor(private $scope, private LocationDetails, public location) {
            super()

            this._location = angular.copy(location)
            this.zoomToLocation()
        }

        save(): void {
            this.location.$update().then((location) => {
                this.$scope.form.$setPristine()
                this._location = angular.copy(location)
                this.location = location
                this.response = {
                    success: true
                }
            }, (response) => {
                this.$scope.form.$setPristine()
                this.$scope.form.$setDirty()
                this.response = {
                    success: false
                }
            })
        }

        // extension of form $dirty because the location's latitude and longitude could change as well and they are not a part of the form
        isDirty() {
            return this.$scope.form.$dirty || !angular.equals(this.location, this._location)
        }

        nameExists(value): boolean {
            return this._location.name == value
                ? true
                : this.LocationDetails.nameUnique({ value: value }).$promise
        }

        search_changed(autocomplete) {
            var place = autocomplete.getPlace()

            if (place.formatted_address) {
                this.location.address = place.formatted_address
                this.location.latitude = place.geometry.location.lat()
                this.location.longitude = place.geometry.location.lng()
                this.zoomToLocation()
            }
        }

    }

    angular
        .module('app')
        .controller('adminLocationDetailsController', AdminLocationDetailsController)
}