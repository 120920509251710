/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    angular
        .module('app')
        .directive('navigation', function ($window) {

            function navigationLink(scope, elem, attr, ctrl) {
                angular.element($window).bind("scroll", function () {
                    var isTop = this.pageYOffset < 150

                    elem.toggleClass('navbar-static-top', isTop)
                    elem.toggleClass('navbar-fixed-top', !isTop)
                })
            }

            return {
                restrict: 'A',
                link: navigationLink
            }
        })

}