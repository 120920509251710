/// <reference path="../../typings/references.d.ts" />

module PartnershipsModule {

    var endpoints = {
        // I'm still not completely sure what should we put here
        'https://pp-cuev-func-p.azurewebsites.net/api/v1/admin': 'https://acismis.onmicrosoft.com/culturaleventsservices',
        '//pp-cuev-func-p.azurewebsites.net/api/v1/admin':'https://acismis.onmicrosoft.com/culturaleventsservices'
    }

    angular
        .module('app')
        .constant('adalAuthenticationServiceConfig', {
            tenant: 'aifs.co.uk',
            clientId: '51db5e7d-4655-47b8-bdd1-91914de76f90',
            cacheLocation: 'localStorage',
            redirectUri: 'https://aifsppculture.com/admin/sign-in-success', //TODO:: use uri name instead of hardcoding
            endpoints: endpoints // in case we will be using CORS
        });

}