/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    function bookingFactory($resource, dataAccessUrl) {

        console.log('bookingFactory ctor');

        return $resource(dataAccessUrl + '/account/bookings');
    }

    angular
        .module('app')
        .factory('Booking', bookingFactory);

}