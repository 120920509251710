/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    function link(scope, elem, attrs, ctrls) {

        var field = ctrls[1];
        var compareField = ctrls[0][attrs.aifsMatch];

        field.$validators.match = function (modelValue, viewValue) {
            return field.$isEmpty(modelValue) || compareField.$viewValue === viewValue;
        };

        compareField.$parsers.unshift(function (value) {
            field.$validate();
            return value;
        })
    }

    angular
        .module('app')
        .directive('aifsMatch', function () {
            return {
                require: ['^form', 'ngModel'],
                restrict: 'A',
                link: link
            };
        });
}