/// <reference path="../../../typings/references.d.ts" />
module TestModule {

    class MenuDirective implements ng.IDirective {
        scope = {
            name: '@menu'
        }
        restrict = 'AE'
        controllerAs = 'menuCtrl'
        controller = MenuController
        bindToController = true
        templateUrl = 'controls/menu/menu.html'
    }


    class MenuController {

        menu

        static $inject = ['$injector', '$scope', '$state', 'Menu']
        constructor(private $injector, private $scope, private $state, private Menu) {

            $scope.$watch('menuCtrl.name', (newValue) => {
                this.menu = Menu(newValue)
            })
        }

        getName(state) {
            var name = state.name
            if (angular.isFunction(name) || angular.isArray(name)) {
                return this.$injector.invoke(name, state, { $scope: this.$scope })
            } else {
                return name
            }
        }

        getParam(item) {
            var param = item.param
            if (angular.isFunction(param) || angular.isArray(param)) {
                return this.$injector.invoke(param, item, { $scope: this.$scope })
            } else {
                return param
            }
        }

        getAction(item) {
            var action = item.action
            if (angular.isFunction(action) || angular.isArray(action)) {
                return this.$injector.invoke(action, item, { $scope: this.$scope })
            } else {
                return action
            }
        }

        isActive(item) {
            return this.$state.includes(item.linkStage || item.state)
        }
    }



    angular
        .module('app')
        .directive('menu', () => new MenuDirective())

}