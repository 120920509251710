/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminAttendeeDetailsController {


        static $inject = ['$scope', '$q','attendee']

        constructor(private $scope, private $q, public attendee) {
           


        }

    }

    angular
        .module('app')
        .controller('AdminAttendeeDetailsController', AdminAttendeeDetailsController)

}