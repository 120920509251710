/// <reference path="../../typings/references.d.ts" />

// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert, Pawel Szajkowski, and Ivan Brkic
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************

module PartnershipsModule {

	function RoutingConfig($urlRouterProvider, $locationProvider, RoutingProvider, $httpProvider, adalAuthenticationServiceProvider, adalAuthenticationServiceConfig) {

		$locationProvider.html5Mode(true)
		$urlRouterProvider.otherwise('/')

		adalAuthenticationServiceProvider.init(adalAuthenticationServiceConfig, $httpProvider)

		$urlRouterProvider.rule(function ($injector, $location) {
			var path = $location.url()

			// check to see if the path has a trailing slash
			if ('/' === path[path.length - 1]) {
				return path.replace(/\/$/, '')
			}

			if (path.indexOf('/?') > 0) {
				return path.replace('/?', '?')
			}

			return false
		})

		var emptyTemplate = 'templates/empty.html'

		var adminData = {
			name: 'admin',
			abstract: true,
			url: '/admin',
			views: {
				'': {
					'admin-anon': {
						template: 'layout/admin/layout.html',
						controller: function ($state) {
							console.log('admin')
							if ($state.current.name != 'admin.home' && $state.current.name != 'admin.signInSuccess') {
								console.log('redirect to admin home')
								$state.go('admin.home')
							}
						}
					},
					'admin-loggedin': {
						template: 'layout/admin/layout.html'
					}
				}
			},
			resolve: {
				$title: function () {
					return 'Home'
				}
			},
			defaultSubstate: 'admin.home',
			states: [
				{
					name: 'home',
					url: '',
					views: {
						'': {
							'public': {
								template: 'admin/home.html',
								controller: 'AdminHomeController as ctrl'
							}
						}
					}
				},
				{
					name: 'signInSuccess',
					url: '/sign-in-success',
					//requireADLogin: true,
					views: {
						'': {
							'admin-loggedin': {
								controller: function ($state) {
									$state.go('admin.home')
								}
							},
							'admin-anon': {
								template: 'admin/anonyomous.html'
							}
						}
					},
					resolve: {
						$title: function () {
							return 'Insufficient permissions'
						}
					}
				},
				{
					name: 'semesters',
					url: '/semesters',
					requireADLogin: true,
					abstract: true,
					views: {
						'': {
							'admin-loggedin': {
								template: emptyTemplate
							}
						}
					},
					resolve: {
						$title: function () {
							return 'Semesters'
						}
					},
					defaultSubstate: 'admin.semesters.list',
					states: [
						{
							name: 'list',
							url: '',
							requireADLogin: true,
							views: {
								'': {
									'admin-loggedin': {
										template: 'admin/semesters.html',
										controller: 'AdminSemestersController as ctrl'
									}
								}
							},
							resolve: {
								semesters: function (Semesters) {
									return Semesters.list().$promise
								}
							}
						},
						{
							name: 'create',
							url: '/create-semester',
							requireADLogin: true,
							views: {
								'': {
									'admin-loggedin': {
										template: 'admin/semester/create.html',
										controller: 'AdminCreateSemesterController as ctrl'
									}
								}
							},
							resolve: {
								$title: function () {
									return 'Create new'
								}
							}
						},
						{
							name: 'details',
							url: '/{semesterCode}',
							requireADLogin: true,
							abstract: true,
							defaultSubstate: 'admin.semesters.details.tabLayout.edit',
							views: {
								'': {
									'admin-loggedin': {
										template: emptyTemplate
									}
								}
							},
							resolve: {
								semesterCode: function ($stateParams) {
									return $stateParams.semesterCode
								},
								semester: function (SemesterDetails, semesterCode) {
									return SemesterDetails.query({ semesterCode: semesterCode }).$promise
								},
								$title: function (semester) {
									return semester.name
								}
							},
							states: [
								{
									name: 'tabLayout',
									url: '',
									requireADLogin: true,
									abstract: true,
									views: {
										'': {
											'admin-loggedin': {
												template: 'admin/semester/layout.html',
												controller: 'AdminSemesterLayoutController as ctrl'
											}
										}
									},
									resolve: {
										semesterCode: function ($stateParams) {
											return $stateParams.semesterCode
										}
									},
									states: [
										{
											name: 'edit',
											url: '',
											requireADLogin: true,
											views: {
												'tabContent': {
													'admin-loggedin': {
														template: 'admin/semester/details.html',
														controller: 'AdminSemesterDetailsController as ctrl'
													}
												}
											},
											resolve: {
												semester: function (SemesterDetails, semesterCode) {
													return SemesterDetails.query({ semesterCode: semesterCode }).$promise
												}
											}
										},
										{
											name: 'events',
											url: '/events',
											requiredADLogin: true,
											views: {
												'tabContent': {
													'admin-loggedin': {
														template: 'admin/event/events.semester.html',
														controller: 'AdminEventsSemesterController as ctrl'
													}
												}
											},
											resolve: {
												$title: function () {
													return 'Events'
												},
												events: function (Events, semesterCode) {
													return Events.list({ semesterCode: semesterCode }).$promise
												}
											}
										},
										{
											name: 'programmes',
											url: '/programmes',
											requiredADLogin: true,
											views: {
												'tabContent': {
													'admin-loggedin': {
														template: 'admin/programme/programmes.html',
														controller: 'AdminProgrammesController as ctrl'
													}
												}
											},
											resolve: {
												$title: function () {
													return 'Programmes'
												},
												programmes: function (Programmes, semesterCode) {
													return Programmes.list({ semesterCode: semesterCode }).$promise
												}
											}
										}
									]
								}
							]
						}
					]
				},
				{
					name: 'programmes',
					url: '/programmes',
					parent: 'admin.semesters.details',
					requiredADLogin: true,
					abstract: true,
					defaultSubstate: 'admin.semesters.details.tabLayout.programmes',
					views: {
						'': {
							'admin-loggedin': {
								template: emptyTemplate
							}
						}
					},
					resolve: {
						semesterCode: function ($stateParams) {
							return $stateParams.semesterCode
						},
						$title: function () {
							return 'Programmes'
						}
					},
					states: [
						{
							name: 'create',
							url: '/create-programme',
							requireADLogin: true,
							views: {
								'': {
									'admin-loggedin': {
										template: 'admin/programme/create.html',
										controller: 'AdminCreateProgrammeController as ctrl'
									}
								}
							},
							resolve: {
								$title: function () {
									return 'Create new'
                                },
                                currencies: function (Currencies) {
                                    return Currencies.list().$promise
                                },
                                accountCodes: function (AccountCodes) {
                                    return AccountCodes.list().$promise
                                }
                            }
						},
						{
							name: 'details',
							url: '/{programmeCode}',
							abstract: true,
							requireADLogin: true,
							defaultSubstate: 'admin.programmes.details.tabLayout.edit',
							views: {
								'': {
									'admin-loggedin': {
										template: emptyTemplate
									}
								}
							},
							resolve: {
								programmeCode: function ($stateParams) {
									return $stateParams.programmeCode
								},
								programme: function (semesterCode, programmeCode, ProgrammeDetails) {
									return ProgrammeDetails.query({ semesterCode: semesterCode, programmeCode: programmeCode }).$promise
								},
								$title: function (programme) {
									return programme.name
                                },
                                currencies: function (Currencies) {
                                    return Currencies.list().$promise
                                }
							},
							states: [
								{
									name: 'tabLayout',
									url: '',
									abstract: true,
									requireADLogin: true,
									views: {
										'': {
											'admin-loggedin': {
												template: 'admin/programme/layout.html',
												controller: 'AdminProgrammeLayoutController as ctrl'
											}
										}
									},
									states: [
										{

											name: 'edit',
											url: '',
											requiredADLogin: true,
											views: {
												'tabContent': {
													'admin-loggedin': {
														template: 'admin/programme/details.html',
														controller: 'adminProgrammeDetailsController as ctrl'
													}
												}
											}
										},
										{
											name: 'boundEvents',
											url: '/events',
											requiredADLogin: true,
											views: {
												'tabContent': {
													'admin-loggedin': {
														template: 'admin/programme/boundEvents.html',
														controller: 'AdminBoundEventsController as ctrl'
													}
												}
											},
											resolve: {
												events: function (BoundEvents, semesterCode, programmeCode) {
													return BoundEvents.list({ semesterCode: semesterCode, programmeCode: programmeCode }).$promise
                                                },
                                                currencyId: function (programme) {
                                                    return programme.currency.currencyId
                                                },
												availableEvents: function (Events, semesterCode, currencyId) {
                                                    return Events.available({ semesterCode: semesterCode, defaultCurrencyId: currencyId }).$promise
												},
												$title: function () {
													return 'Bound Events'
												}
											}
										}
									]
								}
							]
						}
					]
				},
				{
					name: 'locations',
					url: '/locations',
					requireADLogin: true,
					abstract: true,
					views: {
						'': {
							'admin-loggedin': {
								template: emptyTemplate
							}
						}
					},
					resolve: {
						$title: function () {
							return 'Locations'
						}
					},
					defaultSubstate: 'admin.locations.list',
					states: [
						{
							name: 'list',
							url: '',
							requireADLogin: true,
							views: {
								'': {
									'admin-loggedin': {
										template: 'admin/locations.html',
										controller: 'AdminLocationsController as ctrl'
									}
								}
							},
							resolve: {
								locations: function (Locations) {
									return Locations.list().$promise
								}
							}
						},
						{
							name: 'create',
							url: '/create-location',
							requireADLogin: true,
							views: {
								'': {
									'admin-loggedin': {
										template: 'admin/location/create.html',
										controller: 'AdminCreateLocationController as ctrl'
									}
								}
							},
							resolve: {
								$title: function () {
									return 'Create new'
								}
							}
						},
						{
							name: 'details',
							url: '/{locationId}',
							requireADLogin: true,
							views: {
								'': {
									'admin-loggedin': {
										template: 'admin/location/details.html',
										controller: 'adminLocationDetailsController as ctrl'
									}
								}
							},
							resolve: {
								locationId: function ($stateParams) {
									return $stateParams.locationId
								},
								location: function (locationId, LocationDetails) {
									return LocationDetails.query({ locationId: locationId }).$promise
								},
								$title: function (location) {
									return location.name
								}
							}
						}
					]

				},
				{
					name: 'activities',
					url: '/activities',
					sticky: true,
					requiredADLogin: true,
					abstract: true,
					views: {
						'': {
							'admin-loggedin': {
								template: emptyTemplate
							}
						}
					},
					resolve: {
						$title: function () {
							return 'Activities'
						}
					},
					defaultSubstate: 'admin.activities.list',
					states: [
						{
							name: 'list',
							url: '',
							requireADLogin: true,
							views: {
								'': {
									'admin-loggedin': {
										template: 'admin/activities.html',
										controller: 'AdminActivitiesController as ctrl'
									}
								}
							},
							resolve: {
								activities: function (Activities) {
									return Activities.list().$promise
								}
							}
						},
						{
							name: 'create',
							url: '/create-activity',
							requireADLogin: true,
							views: {
								'': {
									'admin-loggedin': {
										template: 'admin/activities/create.html',
										controller: 'AdminCreateActivityController as ctrl'
									}
								}
							},
							resolve: {
								$title: function () {
									return 'Create new'
								},
								locations: function (Locations) {
									return Locations.list().$promise
								}
							}
						},
						{
							name: 'details',
							url: '/{activityId}',
							requireADLogin: true,
							abstract: true,
							defaultSubstate: 'admin.activities.details.tabLayout.edit',
							views: {
								'': {
									'admin-loggedin': {
										template: emptyTemplate
									}
								}
							},
							resolve: {
								activityId: function ($stateParams) {
									return $stateParams.activityId
								},
								activity: function (ActivityDetails, activityId) {
									return ActivityDetails.query({ activityId: activityId }).$promise
								},
								$title: function (activity) {
									return activity.title
								}
							},
							states: [
								{
									name: 'tabLayout',
									url: '',
									requireADLogin: true,
									abstract: true,
									views: {
										'': {
											'admin-loggedin': {
												template: 'admin/activities/layout.html',
												controller: 'AdminActivityLayoutController as ctrl'
											}
										}
									},
									states: [
										{
											name: 'edit',
											url: '',
											requireADLogin: true,
											views: {
												'tabContent': {
													'admin-loggedin': {
														template: 'admin/activities/details.html',
														controller: 'AdminActivityDetailsController as ctrl'
													}
												}
											},
											resolve: {
												activity: function (ActivityDetails, activityId) {
													return ActivityDetails.query({ activityId: activityId }).$promise
												},
												locations: function (Locations) {
													return Locations.list().$promise
												}
											}
										},
										{
											name: 'events',
											url: '/events',
											requiredADLogin: true,
											views: {
												'tabContent': {
													'admin-loggedin': {
														template: 'admin/event/events.activity.html',
														controller: 'AdminEventsActivityController as ctrl'
													}
												}
											},
											resolve: {
												events: function (Events, activityId) {
													return Events.fromActivity({ activityId: activityId }).$promise
												},
												$title: function () {
													return 'Events'
												}
											}
										}
									]
								}
							]
						}
					]
				},
				{
					name: 'events',
					url: '/events',
					requiredADLogin: true,
					abstract: true,
					parent: 'admin.activities.details',
					defaultSubstate: 'admin.activities.details.tabLayout.events',
					views: {
						'': {
							'admin-loggedin': {
								template: emptyTemplate
							}
						}
					},
					states: [
						{
							name: 'create',
							url: '/create-event',
							requireADLogin: true,
							views: {
								'': {
									'admin-loggedin': {
										template: 'admin/event/create.html',
										controller: 'AdminCreateEventController as ctrl'
									}
								}
							},
							resolve: {
								$title: function () {
									return 'Create new'
                                },
                                currencies: function (Currencies) {
                                    return Currencies.list().$promise
                                }

							}
						},
						{
							name: 'details',
							url: '/:eventId',
							requiredADLogin: true,
							views: {
								'': {
									'admin-loggedin': {
										template: 'admin/event/details.html',
										controller: 'AdminEventDetailsController as ctrl'
									}
								}
							},
							resolve: {
								eventId: function ($stateParams) {
									return $stateParams.eventId
								},
								event: function (EventDetails, activityId, eventId) {
									return EventDetails.query({ activityId: activityId, eventId: eventId }).$promise
								},
								$title: function (event, $filter) {
									return $filter('date')(event.startDate, 'medium', '+0000') || 'TBD'
                                },
                                currencies: function (Currencies) {
                                    return Currencies.list().$promise
                                }
							}
						}
					],
					resolve: {
						$title: function () {
							return 'Events'
						}
					}
				},
				{
					name: 'attendees',
					url: '/attendees',
					sticky: true,
					requiredADLogin: true,
					abstract: true,
					views: {
						'': {
							'admin-loggedin': {
								template: emptyTemplate
							}
						}
					},
					resolve: {
						$title: function () {
							return 'Students'
						}
					},
					defaultSubstate: 'admin.attendees.list',
					states: [
						{
							name: 'list',
							url: '',
							requireADLogin: true,
							views: {
								'': {
									'admin-loggedin': {
										template: 'admin/attendees.html',
										controller: 'AdminAttendeesController as ctrl'
									}
								}
							},
							resolve: {
								semesters: function (Semesters) {
									return Semesters.list().$promise
								}
							}
						},
						{
							name: 'details',
							url: '/{attendeeId:int}',
							requireADLogin: true,
							abstract: true,
							defaultSubstate: 'admin.attendees.details.tabLayout.edit',
							views: {
								'': {
									'admin-loggedin': {
										template: emptyTemplate
									}
								}
							},
							resolve: {
								attendeeId: function ($stateParams) {
									return $stateParams.attendeeId
								},
								attendee: function (AttendeesDetails, attendeeId) {
									return AttendeesDetails.query({ attendeeId: attendeeId }).$promise
								},
								$title: function (attendee) {
									return attendee.firstName + attendee.lastName
								}
							},
							states: [
								{
									name: 'tabLayout',
									url: '',
									requireADLogin: true,
									abstract: true,
									views: {
										'': {
											'admin-loggedin': {
												template: 'admin/attendees/layout.html',
												controller: 'AdminActivityLayoutController as ctrl'
											}
										}
									},
									states: [
										{
											name: 'edit',
											url: '',
											requireADLogin: true,
											views: {
												'tabContent': {
													'admin-loggedin': {
														template: 'admin/attendees/details.html',
														controller: 'AdminAttendeeDetailsController as ctrl'
													}
												}
											},
											// resolve: {
											// 	activity: function (ActivityDetails, activityId) {
											// 		return ActivityDetails.query({ activityId: activityId }).$promise
											// 	},
											// 	locations: function (Locations) {
											// 		return Locations.list().$promise
											// 	}
											// }
										}
									]
								}
							]
						}
					]
				}
			]
		}
		RoutingProvider.addState(adminData)

		var data = {
			name: 'app',
			abstract: true,
			url: '',
			views: {
				'': {
					'public': {
						controller: 'UserController',
						template: 'layout/user/user.html'
					}
				}
			},
			states: [
				{
					name: 'tickets',
					url: '/tickets',
					views: {
						'': {
							'loggedinuser': {
								controller: 'MyTicketsController',
								template: 'tickets/MyTickets.html'
							}
						}
					},
					resolve: {
						bookings: function (Booking) {
							return Booking.query().$promise
						},
					}
				},
				{
					name: 'contact',
					url: '/contact',
					views: {
						'': {
							'public': {
								controller: 'ContactController',
								template: 'contact/contact.html'
							}
						}
					}
				},
				{
					name: 'signIn',
					url: '/sign-in',
					views: {
						'': {
							'anon': {
								controller: 'SignInController',
								template: 'account/signIn.html'
							}
						}
					}
				},
				{
					name: 'signOut',
					url: '/sign-out',
					resolve: {
						signout: function (Auth) {
							Auth.signout()
						}
					}
				},
				{
					name: 'externalReference',
					url: '/external/*token',
					resolve: {
						signin: function (Auth, $state, $dialogs, $stateParams) {
                            return Auth.externalSignIn($stateParams.token)
								.then(function (user) {
									if (user.redirectTo == '/account/reset_password')
										$state.go('app.resetPassword')
								}, function (error) {
									$dialogs.error(error.title, error.message)
								})
						}
					}
				},
				{
					name: 'changePassword',
					url: '/change-password',
					views: {
						'': {
							'loggedinuser': {
								controller: 'ChangePasswordController',
								template: 'account/changePassword.html'
							}
						}
					}
				},
				{
					name: 'forgotPassword',
					url: '/forgot-password',
					resolve: {
						email: function () { }
					},
					views: {
						'': {
							'anon': {
								controller: 'ForgotPasswordController',
								template: 'account/forgotPassword.html'
							}
						}
					}
				},
				{
					name: 'forgotPasswordEmail',
					url: '/forgot-password/{email}',
					resolve: {
						email: function ($stateParams) {
							return $stateParams.email
						}
					},
					views: {
						'': {
							'anon': {
								controller: 'ForgotPasswordController',
								template: 'account/forgotPassword.html'
							}
						}
					}
				},
				{
					name: 'resetPassword',
					url: '/reset-password',
					views: {
						'': {
							'loggedinuser': {
								controller: 'ResetPasswordController',
								template: 'account/resetPassword.html'
							}
						}
					}

				},
				{
					name: 'shoppingCart',
					url: '/shopping-cart',
					views: {
						'': {
							'public': {
								controller: 'ShoppingCartController',
								template: 'cart/shoppingCart.html'
							}
						}
					},
					resolve: {
						programme: function (Programme) {
							console.log('resolving programme')
							return Programme.get()
						}
					}
				},
				{
					name: 'bookSuccess',
					url: '/book-success/{reference}',
					views: {
						'': {
							'public': {
								controller: 'BookingResultController',
								template: 'cart/bookingResult.html'
							}
						}
					},
					resolve: {
						result: function ($stateParams) {
							return {
								success: true,
								payment: false,
								reference: $stateParams.reference
							}
						}
					}
				},
				{
					name: 'paymentSuccess',
					url: '/payment-success',
					views: {
						'': {
							'public': {
								controller: 'BookingResultController',
								template: 'cart/bookingResult.html'
							}
						}
					},
					resolve: {
						result: function ($location) {
							var orderreference = $location.search().orderreference
							return {
								success: true,
								payment: true,
								reference: orderreference
							}
						}
					}
				},
				{
					name: 'paymentFailed',
					url: '/payment-failed',
					views: {
						'': {
							'public': {
								controller: 'BookingResultController',
								template: 'cart/bookingResult.html'
							}
						}
					},
					resolve: {
						result: function ($location) {
							var errorCode = $location.search().errorcode
							return {
								success: false,
								payment: true,
								reason: errorCode
							}
						}
					}
				},
				{
					name: 'privacy',
					url: '/privacy',
					views: {
						'': {
							'public': {
								template: 'privacyAndTerms/privacy.html'
							}
						}
					}
				},
				{
					name: 'termsAndConditions',
					url: '/termsconditions',
					views: {
						'': {
							'public': {
								template: 'privacyAndTerms/termsAndConditions.html'
							}
						}
					}
				},
				{
					name: 'home',
					url: '/:programmeCode',
					abstract: true,
					template: '<div ui-view=""></div>',
					resolve: {
						programme: function (Programme, $state, $stateParams) {
							if ($stateParams.programmeCode) {
								return Programme.get($stateParams.programmeCode)
							}
						}

					},
					states: [
						{
							name: 'events',
							url: '',
							views: {
								'': {
									'public': {
										controller: 'EventlistController',
										template: 'events/eventlist.html'
									}
								}
							},
							resolve: {
								eventlist: function ($state, $stateParams, Event, programme) {
									console.log('resolve events')

									if (programme) {
                                        return Event.query({
											programmeCode: programme.code
										}).$promise
									}
								}
							}
						},
						{
							name: 'eventDetails',
							url: '/events/:id',
							views: {
								'': {
									'public': {
										controller: 'EventDetailsController as ctrl',
										template: 'events/eventdetails.html'
									}
								}
							},
							resolve: {
								event: function (Event, $stateParams, programme) {
									console.log('resolve eventDetails - programme is ' + (programme && programme.code))
									if (programme) {
										console.log('capturing for id ')

										return Event.getEvent({
											programmeCode: programme.code,
											id: $stateParams.id
										}).$promise
									}
								},
								related: function (Event, $stateParams, programme) {
									if (programme) {
										return Event.getRelated({
											programmeCode: programme.code,
											id: $stateParams.id
										}).$promise
									}
								}
							}
						}

					]
				}
			]

		}
		RoutingProvider.addState(data)
	}

	angular
		.module('app')
		.config(RoutingConfig)

}