/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    class AdminHomeController {

        testResponse

        static $inject = ['$scope', 'adalAuthenticationService', 'Test']

        constructor($scope, private adalAuthenticationService, private Test) {
            // this is referencing adal module to do login

            //userInfo is defined at the $rootscope with adalAngular module
        }

        logout() {
            this.adalAuthenticationService.logOut()
        }

        login () {
            this.adalAuthenticationService.login()
        }

        testRest() {
            this.Test.test().$promise.then((response) => {
                console.info(response)
                this.testResponse = response
            })
        }
    }

    angular
        .module('app')
        .controller('AdminHomeController', AdminHomeController)

}