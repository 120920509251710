/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {


    class SignInController {

        static $inject = ['$rootScope', '$scope', '$dialogs', 'Auth', 'storage'];

        constructor($rootScope, $scope, $dialogs, Auth, storage) {

            $scope.newUser = {
                programme: storage.get('programmeCode')
            };
            $scope.user = {};
            $scope.forms = {};

            function HandleSuccess() {
                $rootScope.$broadcast('loader:stop');
            }

            function HandleError(data) {
                $rootScope.$broadcast('loader:stop');
                if (data.type && data.title)
                    $dialogs[data.type](data.title, data.message);
                else
                    $dialogs.error('Something went wrong :(', 'Please try again after a few seconds');
            }

            $scope.register = function () {
                if ($scope.forms.register.$valid) {
                    $rootScope.$broadcast('loader:start');
                    Auth.signup($scope.newUser).then(null, HandleError);
                }
            }

            $scope.signIn = function () {
                if ($scope.forms.signIn.$valid) {
                    $rootScope.$broadcast('loader:start');
                    Auth.signin($scope.user).then(null, HandleError);
                }
            }
        }

    }

    angular
        .module("app")
        .controller("SignInController", SignInController);

}