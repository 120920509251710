/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    function ShoppingCartController($scope, ShoppingCartService) {
        var itemCount = ShoppingCartService.items().length
        $scope.basketCount = itemCount
    }

    angular
        .module('app')
        .directive('shoppingCart', function (ShoppingCartService) {
            return {
                restrict: 'AE',
                scope: {
                    name: '@cart'
                },
                controller: ShoppingCartController,
                templateUrl: 'templates/_layout/cart.html',
                link: function (scope: any, element, attrs) {
                    scope.$on('shoppingCart:change', function () {

                        var itemCount = ShoppingCartService.items().length
                        console.log('cart watch: itemCount = ' + itemCount)
                        scope.basketCount = itemCount
                    })

                }
            }
        })
}