/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminProgrammesController {

        static $inject = ['programmes', 'semester']

        constructor(public programmes, public semester) {
        }
    }

    angular
        .module('app')
        .controller('AdminProgrammesController', AdminProgrammesController)

}