/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    function ProgrammeCodeProvider(Auth, storage) {

        this.get = function (programmeCode) {

            // user programme have bigest priority
            if (Auth.isLoggedIn() && Auth.user.programme)
                programmeCode = Auth.user.programme;


            // if programmeCode is not define try to get from local store. Otherwise save programmeCode
            if (!programmeCode)
                programmeCode = storage.get('programmeCode');
            else
                storage.set('programmeCode', programmeCode);

            // TODO: remove - temporary fix for RISS15 programme merge
            if (programmeCode.indexOf('RISS15A') === 0)
                programmeCode = 'RISS15';

            return programmeCode;
        }
    }

    function ProgrammeCodeFactory(Auth, storage) {
        console.log('ProgrammeCodeFactory ctor');
        return new ProgrammeCodeProvider(Auth, storage);
    }

    angular
        .module('app')
        .factory('ProgrammeCode', ProgrammeCodeFactory);

}