/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminCreateActivityController {

        response
        activity

        private defaultZoom: number = 19
        private _location

        map = {
            center: {
                latitude: 54.6,
                longitude: -4.6
            },
            zoom: 5
        }

        static $inject = ['$scope', 'ActivityDetails', 'locations', '$state']

        constructor(private $scope, private ActivityDetails, public locations, private $state) {
        }

        resetResponse() {
            this.response = null
        }

        zoomToLocation(location = this._location, zoom: number = this.defaultZoom): void {
            this.map.center.latitude = location.latitude
            this.map.center.longitude = location.longitude
            this.map.zoom = zoom
        }

        create() {
            this.ActivityDetails.create(this.activity).$promise.then((response) => {
                console.log(response)
                this.$state.go('admin.activities.details.tabLayout.edit', { activityId: response.activity.id })
            }, (response) => {
                this.$scope.response = {
                    success: false
                }
            })
        }
    }

    angular
        .module('app')
        .controller('AdminCreateActivityController', AdminCreateActivityController)

}

