/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    function priceFiter($filter) {
        return function (value, whenTrue, whenFalse) {

            if (isFinite(value) && value <= 0) {
                return 'Free';
            }

            return $filter('currency')(value, whenTrue, whenFalse);;
        };
    }

    angular
        .module('app')
        .filter('price', priceFiter);

}