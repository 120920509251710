/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminSemesterDetailsController {

        response

        today: Date = new Date() // make it a constant

        private _semester

        static $inject = ['$scope', 'semester', 'SemesterDetails']

        constructor(private $scope, public semester, private SemesterDetails) {
            this._semester = angular.copy(semester)
        }

        resetResponse() {
            this.response = null
        }

        nameExists(value) {
            return this._semester.name === value
                ? true
                : this.SemesterDetails.nameUnique({ value: value }).$promise
        }

        save() {
            this.semester.$update().then((response) => {
                this.$scope.form.$setPristine()
                this._semester = angular.copy(this.semester)

                this.response = {
                    success: true
                }
            }, (response) => {
                this.$scope.form.$setPristine()
                this.$scope.form.$setDirty()
                this.$scope.response = {
                    success: false
                }
            })
        }
    }

    angular
        .module('app')
        .controller('AdminSemesterDetailsController', AdminSemesterDetailsController)

}