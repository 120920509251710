/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    angular
        .module('app')
        .directive('resource', function ($parse, ResourcesLoader) {

            function resourceLink(scope, elem, attr, ctrl) {
                ResourcesLoader().then(function (data) {
                    var value = $parse(attr.resource)(data)
                    elem.text(value)
                })
            }

            return {
                restrict: 'AE',
                link: resourceLink,
            }
        })
}