/// <reference path="../../../typings/references.d.ts" />

module PartnershipsModule {

    class AdminProgrammeDetailsController {

        response

        private _programme

        static $inject = ['$scope', 'programme', 'ProgrammeDetails', 'semester', 'currencies']

        constructor(private $scope, public programme, private ProgrammeDetails, public semester, public currencies) {
            programme.semesterCode = semester.code

            this._programme = angular.copy(programme)
        }

        save() {
            this.programme.$update().then((response) => {
                this.$scope.form.$setPristine()
                this._programme = angular.copy(this.programme)

                this.response = {
                    success: true
                }
            }, (response) => {
                this.$scope.form.$setPristine()
                this.$scope.form.$setDirty()
                this.response = {
                    success: false
                }
            })
        }

        resetResponse() {
            this.response = null
        }

        nameExists(value) {
            return this._programme.name === value
                ? true
                : this.ProgrammeDetails.nameUnique({ semesterCode: this.semester.code, value: value }).$promise
        }
    }

    angular
        .module('app')
        .controller('adminProgrammeDetailsController', AdminProgrammeDetailsController)

}