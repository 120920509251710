/// <reference path="../../typings/references.d.ts" />
module PartnershipsModule {

    angular
        .module("app")
        .directive('aifsInterval', function ($interval) {

            function link(scope, elem, attrs) {
                var deley = parseInt(attrs.aifsIntervalDeley);
                var interval = setInterval(update, deley);

                function update() {
                    var value = scope.$eval(attrs.aifsInterval);
                    elem.text(value);
                }

                elem.on('$destroy', function () {
                    clearInterval(interval);
                });
            }

            return {
                restrict: 'A',
                link: link
            };
        });

}